import React, { Component } from 'react';

const Controller = Wrapper => class extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLogged: false,
    }
  }
  
  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }
  
  componentWillUnmount() {
    document.body.style.overflow = ''
  }
  
  render() {
    return (
      <Wrapper
        {...this.state}
        {...this.props}
      />
    )
  }
}

export default Controller
