import styled, { css } from "styled-components";
import { COLOR } from "@/config";

const Clearfix = css`
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: 306px;
  border-top: 1px solid ${COLOR.gray_5};
  background-color: ${COLOR.white_0};
`;

export const Wrap = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Terms = styled.div`
  width: 100%;
  height: 40px;
  ${Clearfix};
  padding: 0 16px;
  text-align: center;
  a {
    color: #242424;
    font-size: 10px;
    line-height: 40px;
    margin-right: 3%;
  }
`;

export const Info = styled.div`
  padding: 16px 16px 0;
  font-size: 10px;
  color: #868686;
  line-height: 1.6;
`;

export const Content = styled(Info)`
  p {
    text-transform: uppercase;
    span {
      font-weight: 700;
    }
  }
`;

export const SNSWrap = styled.div`
  padding: 16px 16px 0;
`;

const SNSLink = styled.a`
  display: inline-block;
  width: 20px;

  & + a {
    margin-left: 24px;
  }
`;

export const Insta = styled(SNSLink).attrs({
  href: "https://www.instagram.com/fdding_official/?hl=ko",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 20px;
  background: url(${require("./images/m-sns-insta.svg")}) center center
    no-repeat;
  background-size: cover;
`;

export const Blog = styled(SNSLink).attrs({
  href: "https://blog.naver.com/fddingofficial",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 18.5px;
  background: url(${require("./images/m-sns-blog.svg")}) center center no-repeat;
  background-size: cover;
`;

export const Facebook = styled(SNSLink).attrs({
  href: "https://www.facebook.com/fddingofficial/",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 20px;
  background: url(${require("./images/m-sns-facebook.svg")}) center center
    no-repeat;
  background-size: cover;
`;

export const License = styled.p`
  padding: 16px 16px 0;
  font-size: 10px;
  line-height: 1.5;
  color: ${COLOR.gray_2};
`;

const OuterLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})``;

export const Membership = styled(OuterLink).attrs({
  href: "https://membership.fdding.com",
})``;

export const Privacy = styled(OuterLink).attrs({
  href: "https://static.fdding.com/terms/privacy.html",
})``;

export const Condition = styled(OuterLink).attrs({
  href: "https://static.fdding.com/terms/terms-and-conditions.html",
})``;

export const Contract = styled(OuterLink).attrs({
  href: "https://blog.naver.com/fddingofficial/222157664820",
})``;
