export const FETCH_PLAN_DATA = "FETCH_PLAN_DATA";
export const CLEAR_PLAN_DATA = "CLEAR_PLAN_DATA";
export const CHANGE_PLAN_DATA = "CHANGE_PLAN_DATA";
export const CHANGE_PLAN_ADDITIONAL_DATA = "CHANGE_PLAN_ADDITIONAL_DATA";

export const fetchPlanData = (data) => {
  return {
    type: FETCH_PLAN_DATA,
    payload: {
      data,
    },
  };
};

export const clearPlanData = () => {
  return {
    type: CLEAR_PLAN_DATA,
  };
};

export const changePlanData = (name, value) => {
  return {
    type: CHANGE_PLAN_DATA,
    payload: {
      name,
      value,
    },
  };
};

export const changePlanAdditionalData = (name, value) => {
  return {
    type: CHANGE_PLAN_ADDITIONAL_DATA,
    payload: {
      name,
      value,
    },
  };
};
