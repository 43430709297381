import styled, { css } from "styled-components";
import { MIN_WIDTH, COLOR } from "@/config";

const Clearfix = css`
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: 230px;
  border-top: 1px solid ${COLOR.gray_5};
  background-color: ${COLOR.white_0};
`;

export const Wrap = styled.div`
  width: ${MIN_WIDTH}px;
  height: 100%;
  margin: 0 auto;
  padding: 32px 120px;

  ${Clearfix}
`;

export const Logo = styled.div`
  float: left;
  width: 88px;
  height: 37px;
  background: url(${require("./images/bi.svg")}) center center no-repeat;
  background-size: cover;
`;

export const Info = styled.div`
  float: left;
  padding-left: 76px;

  > div > p {
    color: ${COLOR.gray_1};
    font-size: 12px;
    line-height: 20px;
  }

  > p {
    margin-top: 16px;
    color: ${COLOR.gray_2};
    font-size: 12px;
    line-height: 18px;
  }
`;

export const Right = styled.div`
  float: right;
  text-align: right;
`;

export const Terms = styled.div`
  margin-bottom: 17px;

  > a {
    color: ${COLOR.gray_1};
    font-size: 12px;
    font-weight: 700;
    line-height: 13px;

    &:hover {
      color: ${COLOR.gray_0};
    }

    & + a {
      margin-left: 40px;
    }
  }
`;

export const Content = styled.div`
  margin-bottom: 16px;

  > p {
    color: ${COLOR.gray_1};
    font-size: 12px;
    line-height: 20px;

    > span {
      font-weight: 700;
    }
  }
`;

export const SNSWrap = styled.div`
  > a {
    vertical-align: middle;
  }
`;

const SNSLink = styled.a`
  display: inline-block;
  width: 24px;

  & + a {
    margin-left: 24px;
  }
`;

export const Insta = styled(SNSLink).attrs({
  href: "https://www.instagram.com/fdding_official/?hl=ko",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 24px;
  background: url(${require("./images/sns-insta.svg")}) center center no-repeat;
  background-size: cover;
`;

export const Blog = styled(SNSLink).attrs({
  href: "https://blog.naver.com/fddingofficial",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 22px;
  background: url(${require("./images/sns-blog.svg")}) center center no-repeat;
  background-size: cover;
`;

export const Facebook = styled(SNSLink).attrs({
  href: "https://www.facebook.com/fddingofficial/",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  height: 24px;
  background: url(${require("./images/sns-facebook.svg")}) center center
    no-repeat;
  background-size: cover;
`;

export const OuterLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})``;

export const Membership = styled(OuterLink).attrs({
  href: "https://membership.fdding.com",
})``;

export const Privacy = styled(OuterLink).attrs({
  href: "https://static.fdding.com/terms/privacy.html",
})``;

export const Condition = styled(OuterLink).attrs({
  href: "https://static.fdding.com/terms/terms-and-conditions.html",
})``;
