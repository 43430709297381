import React from "react";

import { Icon } from "../styled";

import arrowRight from "./images/ico-arrow-right.svg";
import arrowDown from "./images/ico-arrow-down.svg";
import arrowUp from "./images/ico-arrow-up.svg";

const ArrowRight = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={arrowRight} />;
};

const ArrowDown = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={arrowDown} />;
};

const ArrowUp = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={arrowUp} />;
};

const Arrow = ({ type, width, height, ...props }) => {
  if (type === "right") {
    return <ArrowRight {...props} width={width} height={height} />;
  } else if (type === "up") {
    return <ArrowUp {...props} width={width} height={height} />;
  } else if (type === "down") {
    return <ArrowDown {...props} width={width} height={height} />;
  } else {
    return null;
  }
};

export default Arrow;
