import { put, call, takeLatest } from 'redux-saga/effects';

import {
  REQUEST_FAVORITE_LIST,
  successFavoriteList,
  failFavoriteList
} from '../Actions/favorite';

import { favorite } from './api';

function* fetchFavorite() {
  try {
    const data = yield call(favorite.getData);

    yield put(successFavoriteList(data));
  } catch (e) {
    yield put(failFavoriteList());
  }
}

function* watchFetchFavorite() {
  yield takeLatest([REQUEST_FAVORITE_LIST], fetchFavorite);
}

export default watchFetchFavorite;
