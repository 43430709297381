import styled, { css } from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { MIN_WIDTH, COLOR } from "@/config";
import Balloon from "@/ViewWeb/Components/Balloon";

const Clearfix = css`
  &::after {
    display: block;
    content: "";
    clear: both;
  }
`;

const BaseCss = css`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 120px;
`;

export const Header = styled.header`
  width: 100%;
  /* height: ${(props) => (props.show === true ? "194px" : "154px")}; */
`;

export const Wrap = styled.div`
  width: 100%;
  height: 32px;
  background-color: ${COLOR.white_0};

  & > div {
    ${BaseCss}
  }
`;

export const FlagGroup = styled.div`
  float: left;
  margin-top: 5px;

  & > a::before {
    content: "|";
    display: inline-block;
    color: #ddd;
    margin: 0 10px;
  }
  & > a:first-child::before {
    display: none;
  }
`;

export const Logo = styled(Link)`
  display: block;
  width: 88px;
  height: 37px;
  background: url(${require("./images/bi.svg")}) center center no-repeat;
  background-size: cover;
`;

export const MiddleWrap = styled.div`
  position: relative;
  width: 100%;

  & > div {
    ${BaseCss}
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  border-bottom: solid 1px #ededed;
`;

export const MembershipLink = styled.a.attrs({
  href: "https://membership.fdding.com",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  display: block;
  position: absolute;
  top: 50%;
  right: 120px;
  transform: translateY(-50%);
`;

export const MembershipLogo = styled.div`
  width: 164px;
  height: 42px;
  display: block;
  & > div {
    margin-top: 3px;
    padding-top: 3px;
    height: 23px;
    background-color: #242424;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border-radius: 2px;
    text-align: center;
  }
`;

export const MainMenu = styled.div`
  ${Clearfix}
  position: absolute;
  top: 2px;
  left: 245px;
`;

export const MenuButton = styled(NavLink)`
  position: relative;
  float: left;
  margin-left: 30px;
  padding: 24px 0;
  font-size: 14px;
  line-height: 1.42;
  color: #333;

  box-sizing: border-box;

  > img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
  }

  &:hover,
  &.active {
    font-weight: bold;
  }
  &:hover::before,
  &.active::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #333;
  }

  &:first-child {
    margin-left: 0;
    padding-right: 10px;
  }
`;

export const MenuDropdownWrapper = styled.div`
  position: relative;
  float: left;

  &.opened .menu-dropdown__list {
    display: block;
  }
  &.opened .menu-dropdown__button::after {
    transform: rotate(-180deg);
  }
`;
export const MenuDropdownButton = styled(
  MenuButton.withComponent("button")
).attrs({
  className: "menu-dropdown__button",
})`
  float: none;
  border: 0;
  background: transparent;

  &::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    border: solid 1px #ddd;
    box-sizing: border-box;
    border-radius: 100%;
    background-image: url(${require("./images/arrow-down.svg")});
    background-position: center;
    background-size: 8px 5px;
    background-repeat: no-repeat;
    background-color: #fff;
    vertical-align: middle;
    transition: transform 0.3s;
  }
  &:hover {
    font-weight: normal;
  }
  &:hover::before {
    display: none;
  }
`;

export const MenuDropdown = styled.ul.attrs({
  className: "menu-dropdown__list",
})`
  display: none;
  position: absolute;
  top: 67px;
  left: -16px;
  padding: 0 20px 12px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ededed;
  box-sizing: border-box;
  transform: translateX(-25%);
  background: #fff;
  z-index: 10;

  & .menu-title,
  & a {
    display: block;
    white-space: nowrap;
  }
  & .menu-title {
    font-size: 14px;
    line-height: normal;
    font-weight: bold;
    padding: 11px 0;
  }
  & a {
    padding: 7px 0;
    font-size: 12px;
    line-height: normal;
    color: #333;
    word-break: keep-all;
  }
  & a.active,
  & a:hover {
    color: #c29778;
  }
`;

// 아래는 레거시

export const FlagLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  padding-left: 29px;
  color: ${COLOR.gray_1};
  font-size: 12px;
  position: absolute;
  top: 50%;
  left: 120px;
  transform: translateY(-50%);
`;

export const Flag = styled.div`
  display: inline-block;
  width: 21px;
  height: 14px;
  background: url(${require("./images/english.png")}) center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const EstimateButton = styled.div`
  display: inline-block;
  width: 100px;
  height: 20px;
  background-color: #D8AC8A;
  border-radius: 2px;
  margin-left: 10px;
  font-size: 12px;
`

export const ContractButton = styled.a.attrs({
  href: "https://blog.naver.com/fddingofficial/222157664820",
  target: "_blank",
  rel: "noopener noreferrer",
})`
  display: inline-block;
  width: 136px;
  height: 28px;
  border: 1px solid #ECECEC;
  background-color: #FFF;
  border-radius: 100px;
  margin-top: 7px;
  padding-top: 3px;
  cursor: pointer;

  > span {
    color: #868686;
    font-weight: 400;
    font-size: 12px;
    font-height: 14px;
    margin-left: 14px;
  }
`

export const TopMenu = styled.ul`
  float: right;
  height: 100%;

  > li {
    float: left;
    height: 100%;
    line-height: 32px;
    position: relative;

    > a {
      display: block;
      color: ${COLOR.gray_1};
      font-size: 12px;
    }

    & + li {
      margin-left: 20px;
    }
  }
`;

export const MenuLink = styled(Link)`
  display: inline-block;
  color: ${COLOR.gray_1};
  font-size: 12px;
  font-weight: 400;

  &:hover {
    color: ${COLOR.gray_0};
  }
`;

export const UserMenu = styled.li`
  position: relative;

  &:hover {
    > ul {
      display: block;
    }
  }
`;

export const UserMenuDrop = styled.p`
  color: ${COLOR.gray_1};
  font-size: 12px;
  cursor: pointer;

  > span {
    font-weight: 700;
  }
`;

export const DropDown = styled.ul`
  display: none;
  min-width: 150px;
  padding: 0 5px;
  border: 1px solid #707070;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: absolute;
  top: 33px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-top: 1px solid #707070;
    border-left: 1px solid #707070;
    background-color: #fff;
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%) rotate(45deg);
  }

  > li {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    line-height: 46px;
    text-align: center;

    > a,
    > p {
      display: inline;
      color: ${COLOR.gray_0};
      font-size: 14px;
      font-weight: 300;
      cursor: pointer;
    }

    & + li {
      border-top: 1px solid ${COLOR.gray_5};
    }
  }

  @media only screen and (max-width: ${MIN_WIDTH}px) {
    left: 0;
    right: 0;
    transform: translateX(-51%);

    &::before {
      left: 62%;
    }
  }
`;

export const TopBanner = styled.div`
  text-align: center;
  line-height: normal;
  background-color: #7F343C;

  a {
    display: block;
    padding: 10px 0;
    font-size: 14px;
    color: #fff;

    span,
    svg,
    img {
      vertical-align: middle;
    }
    > span {
      margin-left: 8px;
      &.bold {
        font-weight: bold;
      }
    }
  }
`;

export const HeaderBalloon = styled(Balloon)`
  width: 450px;
  position: absolute;
  top: 48px;
  left: 50%;
  background-color: #242424;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  margin: 0 auto;
  border: 0;
  transform: translateX(-50%);
  & + button {
    margin-top: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    border-bottom: 8px solid #242424;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: -7px;
    left: 60%;
  }

`;
