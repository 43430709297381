export const topBannerHandler = handle => {
  const topBanner = document.querySelector(".top-banner");

  if (!topBanner) {
    return false;
  }

  try {
    if (handle === "show") {
      topBanner.style.display = "block";
    } else if (handle === "hide") {
      topBanner.style.display = "none";
    }
  } catch (err) {
    console.log(err);
  }
};

export default {};
