import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  REQUEST_SNAP_WEDDINGHALL,
  successSnapWeddingHall,
  failSnapWeddingHall
} from "@/Redux/Actions/snapWeddingHall";

import { snap } from "./api";

function* fetchSnapWeddingHall() {
  try {
    const snapWeddingHall = yield select(state => state.snapWeddingHall);
    const { type, folderId, nextPage, limit } = snapWeddingHall;
    const params = {
      type,
      folderId,
      limit,
      // isRepresent: false,
      page: nextPage
    };

    const data = yield call(snap.getCollection, { type, params });

    yield put(successSnapWeddingHall(data));
  } catch (err) {
    yield put(failSnapWeddingHall());
  }
}

export default function* watchFetchSnapWeddingHall() {
  yield takeLatest([REQUEST_SNAP_WEDDINGHALL], fetchSnapWeddingHall);
}
