import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'react-router-dom';
import reset from 'styled-reset';

import { MIN_WIDTH, COLOR } from '@/config';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  * {
    font-family: 'Spoqa Han Sans', sans-serif !important;
    box-sizing: border-box;
  }

  html {
    width: 100%;
    height: 100%;

    body {
      height: 100%;

      #root {
        height: 100%;
      }
    }
  }

  h1, h2, h3 {
    font-weight: 700;
  }

  button {
    outline: none;
    cursor: pointer;
    padding: 0;

    &:disabled {
      cursor: default;
    }
  }

  a {
    text-decoration: none;
  }

  input {
    outline: none;
  }

  .lazyload-placeholder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
${
  '' /* 
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 700;
    src: local('Spoqa Han Sans Bold'),
      url(${require('../Fonts/SpoqaHanSansBold.woff2')}) format('woff2'),
      url(${require('../Fonts/SpoqaHanSansBold.woff')}) format('woff'),
      url(${require('../Fonts/SpoqaHanSansBold.ttf')}) format('truetype');
  }
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 400;
    src: local('Spoqa Han Sans Regular'),
      url(${require('../Fonts/SpoqaHanSansRegular.woff2')}) format('woff2'),
      url(${require('../Fonts/SpoqaHanSansRegular.woff')}) format('woff'),
      url(${require('../Fonts/SpoqaHanSansRegular.ttf')}) format('truetype');
  }
  @font-face {
    font-family: 'Spoqa Han Sans';
    font-weight: 300;
    src: local('Spoqa Han Sans Light'),
      url(${require('../Fonts/SpoqaHanSansLight.woff2')}) format('woff2'),
      url(${require('../Fonts/SpoqaHanSansLight.woff')}) format('woff'),
      url(${require('../Fonts/SpoqaHanSansLight.ttf')}) format('truetype');
  } */
}
`;

export const Main = styled.div`
  width: 100%;
  min-width: ${MIN_WIDTH}px;
  background-color: ${props =>
    props.isErrorPage ? COLOR.gray_6 : COLOR.white_0};
`;

export const BasicLabel = styled.h1`
  font-size: 19px;
  color: ${COLOR.gray_0};
  &:after {
    content: '';
    clear: both;
    display: block;
  }
`;

/*export const BasicLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  float: right;
  font-size: 14px;
  color: ${ COLOR.gray_2 };
`*/

export const BasicLink = styled(Link)`
  float: right;
  font-size: 14px;
  color: ${COLOR.gray_2};
`;

export const MoreBtn = styled.div`
  display: inline-block;
  margin-left: 4px;
  background: url(${require('./images/ico-more.svg')}) no-repeat center center;
  background-size: cover;
  width: 16px;
  height: 16px;
  vertical-align: middle;
`;
