import React, { lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { PrivateRoute, RouteContainer, ViaMobile } from "@/App/Routing";

// 홈
const Home = lazy(() =>
  import("./Pages/Home" /* webpackChunkName: "web-home" */)
);
// 회사 소개
const About = lazy(() =>
  import("./Pages/About" /* webpackChunkName: "web-about" */)
);
// 회원가입
const Signup = lazy(() =>
  import("./Pages/Signup" /* webpackChunkName: "web-signup" */)
);
// 로그인
const SignIn = lazy(() =>
  import("./Pages/SignIn" /* webpackChunkName: "web-signin" */)
);
// 내정보
const Account = lazy(() =>
  import("./Pages/Account" /* webpackChunkName: "web-account" */)
);
// 위시리스트
const Favorite = lazy(() =>
  import("./Pages/Favorite" /* webpackChunkName: "web-favorite" */)
);

// 스냅
const Snap = lazy(() =>
  import("./Pages/Snap" /* webpackChunkName: "web-snap" */)
);

// 제주 기획전
const Plan = lazy(() =>
  import("./Pages/Plan" /* webpackChunkName: "web-plan" */)
);

// 이벤트
const Event = lazy(() =>
  import("./Pages/Event" /* webpackChunkName: "web-event" */)
);

// 작가 촬영 스케줄
const Estimate = lazy(() =>
  import("./Pages/Estimate" /* webpackChunkName: "web-estimate" */)
);

// Magazine
const Magazine = lazy(() =>
  import("./Pages/Magazine" /* webpackChunkName: "web-magazine" */)
);
const MagazineDetail = lazy(() =>
  import("./Pages/MagazineDetail" /* webpackChunkName: "web-magazine-detail" */)
);

//// Gallery
//const Gallery = lazy(() =>
//  import("./Pages/Gallery" /* webpackChunkName: "web-gallery" */)
//);

//// 이용방법
//const HowToUse = lazy(() =>
//  import("./Pages/HowToUse" /* webpackChunkName: "web-how-to-use" */)
//);

// 안심결제
const SafetyPayment = lazy(() =>
  import("./Pages/SafetyPayment" /* webpackChunkName: "safety-payment" */)
);

// 에러
const NotFound = lazy(() =>
  import("./Pages/Error/NotFound" /* webpackChunkName: "web-not-found" */)
);
const ServerError = lazy(() =>
  import("./Pages/Error/ServerError" /* webpackChunkName: "web-server-error" */)
);

const RoutesComponent = () => (
  <Switch>
    {/* 패스 변경으로 인한 redirect */}
    <Redirect from="/product" to={"/"} />
    <Redirect from="/jeju/list/snap.html" to={"/jeju"} />
    <Redirect from="/bonsik/list/convension.html" to={"/main"} />
    <Redirect from="/jeju/list/dressmakeup.html" to={"/dress"} />
    <Redirect from="/new_information.html" to={"/howtouse"} />
    <Redirect from="/fd_gallery.html" to={"/gallery"} />
    <Redirect from="/bonsik/list/house.html" to={"/main"} />
    <Redirect from="/tips/magazine.html" to={"/magazine"} />
    {/* // End 패스 변경으로 인한 redirect */}

    <Route exact path="/" component={RouteContainer(Home)} />
    <Route exact path="/about" component={RouteContainer(About)} />
    <Route exact path="/signup" component={RouteContainer(Signup)} />
    <Route exact path="/signin" component={RouteContainer(SignIn)} />
    <PrivateRoute exact path="/account" component={RouteContainer(Account)} />
    <PrivateRoute exact path="/favorite" component={RouteContainer(Favorite)} />

    <Route path="/main" component={RouteContainer(Snap)} />
    <Route path="/dvd" component={RouteContainer(Snap)} />
    <Route path="/jeju" component={RouteContainer(Snap)} />
    <Route path="/simple" component={RouteContainer(Snap)} />
    <Route path="/dme" component={RouteContainer(Snap)} />


    <Route path="/plan" component={RouteContainer(Plan)} />
    <Route path="/event" component={RouteContainer(Event)} />

    <Route exact path="/estimate" component={RouteContainer(Estimate)} />
    <Route exact path="/magazine" component={RouteContainer(Magazine)} />
    <Route path="/magazine/:id" component={RouteContainer(MagazineDetail)} />

//    {/*<Route exact path="/gallery" component={RouteContainer(Gallery)} />*/}

//    {/*<Route exact path="/howtouse" component={RouteContainer(HowToUse)} />*/}

    <Route path="/safety-payment" component={RouteContainer(SafetyPayment)} />

    <ViaMobile path="/mobile" />
    <Route path="/500" component={RouteContainer(ServerError)} />
    <Route path="/404" component={RouteContainer(NotFound)} />

    <Redirect from="/dress" to={"/dme"} />
    <Redirect to="/404" />
  </Switch>
);

export default RoutesComponent;
