import React from 'react';
import clearIcon from './images/clear.svg';

import { Icon } from '../styled';

const ClearIcon = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={clearIcon} />;
};

export default ClearIcon;
