import React, { useEffect, useRef } from "react";
import swiper from "swiper";
import "swiper/dist/css/swiper.min.css";

/** Swiper 컴포넌트 Swiper API 참고 */
const Swiper = ({ innerRef, options, children, ...props }) => {
  const swiperRef = innerRef ? innerRef : useRef(null);

  useEffect(() => {
    console.log("effect: utils/components/Swiper");
    if (swiperRef.current) {
      new swiper(swiperRef.current, Object.assign({}, defaultOptions, options));
    }

    return () => {};
  }, []);

  if (!children) {
    return null;
  }

  const { pagination, navigation, scrollbar, lazy } = options || {};
  return (
    <div {...props} ref={swiperRef} className="swiper-container">
      <div className="swiper-wrapper">
        {React.Children.map(children, child => {
          const elementChild = child;
          const {
            props: { className }
          } = elementChild;
          return React.cloneElement(elementChild, {
            className: `swiper-slide ${className} ${lazy ? "swiper-lazy" : ""}`
          });
        })}
      </div>

      {/* <!-- If we need pagination --> */}
      {pagination && <div className="swiper-pagination"></div>}

      {/* <!-- If we need navigation buttons --> */}
      {navigation && (
        <>
          <div className="swiper-button-prev"></div>
          <div className="swiper-button-next"></div>
        </>
      )}

      {/* <!-- If we need scrollbar --> */}
      {scrollbar && <div className="swiper-scrollbar"></div>}
    </div>
  );
};

React.forwardRef((props, ref) => <Swiper {...props} innerRef={ref} />);

const defaultOptions = {
  loop: true,
  loopAdditionalSlides: 1,
  threshold: 30
};

export default Swiper;
