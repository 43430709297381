import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Spinner from "@/Components/Spinner";
import { Main, GlobalStyle } from "./styled";

import Header from "./Components/Header";
import Footer from "./Components/Footer";

import Routes from "./routes";

class PageLoading extends React.Component {
  state = {
    headerHeight: 0,
  };

  componentDidMount() {
    const header = document.querySelector("header");

    if (header) {
      const headerHeight = header.clientHeight;
      this.setState({
        headerHeight,
      });
    }
  }

  render() {
    const { headerHeight } = this.state;
    return (
      <Spinner
        style={{
          top: `${headerHeight}px`,
          minWidth: "1440px",
          background: "#fff",
        }}
        type="rect"
      />
    );
  }
}

const isErrorPage =
  window.location.pathname === "/404" || window.location.pathname === "/500";
const Desktop = () => {
  useEffect(() => {
    if (!window.wcs_add) window.wcs_add = {};
    window.wcs_add["wa"] = "s_2d9eaae63dac";
    if (!window._nasa) window._nasa = {};
    window.wcs.inflow();
    window.wcs_do(window._nasa);
  }, []);
  return (
    <Router>
      <Main isErrorPage={isErrorPage}>
        <GlobalStyle />

        <Header />
        <Suspense fallback={<PageLoading />}>
          <Routes />
        </Suspense>
        <Footer />
      </Main>
    </Router>
  );
};
export default Desktop;
