import { call, put, select, takeLatest } from "redux-saga/effects";

import {
  REQUEST_SNAP_COLLECTION,
  successSnapCollection,
  failSnapCollection
} from "@/Redux/Actions/snapCollection";

import { snap } from "./api";

function* fetchCollectList({ payload }) {
  const { type } = payload;
  const getFilter = state => state.filter["collection"][type];

  try {
    const snapCollection = yield select(state => state.snapCollection);
    const filters = yield select(getFilter);
    const { nextPage, limit } = snapCollection;
    let params = {
      type,
      // isRepresent: true,
      page: nextPage,
      limit
    };

    if (filters) {
      params = {
        ...params,
        ...filters
      };
    }

    const data = yield call(snap.getCollection, { type, params });

    yield put(successSnapCollection(data));
  } catch (err) {
    yield put(failSnapCollection());
  }
}

export default function* watchFetchCollectList() {
  yield takeLatest([REQUEST_SNAP_COLLECTION], fetchCollectList);
}
