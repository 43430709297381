import React from "react";
import themes from "@/Themes/default";

import styled from "styled-components";
import { ifProp, prop } from "styled-tools";
import styles from "@/Utils/styles";

const Wrapper = styled.div`
  ${styles.clearfix};
  position: relative;
  width: 100%;
  min-width: ${ifProp("minWidth", prop("minWidth"), themes.limit.minWidth)};
  max-width: ${ifProp("maxWidth", prop("maxWidth"), themes.limit.maxWidth)};
  margin: 0 auto;
  box-sizing: border-box;
`;

const LimitContainer = ({
  innerRef,
  minWidth,
  maxWidth,
  children,
  ...props
}) => (
  <Wrapper minWidth={minWidth} maxWidth={maxWidth} {...props}>
    {children}
  </Wrapper>
);
React.forwardRef((props, ref) => <LimitContainer {...props} innerRef={ref} />);

export default LimitContainer;
