export const UPDATE_SAFETY_PAYMENT_DATA = "UPDATE_SAFETY_PAYMENT_DATA";
export const CLEAR_SAFETY_PAYMENT_DATA = "CLEAR_SAFETY_PAYMENT_DATA";

export const updateSafetyPaymentData = data => ({
  type: UPDATE_SAFETY_PAYMENT_DATA,
  payload: { data }
});

export const clearSafetyPaymentData = () => ({
  type: CLEAR_SAFETY_PAYMENT_DATA
});
