// Polyfill
import "core-js/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import configureStore from "@/Redux/Store/configureStore";
import rootSaga from "@/Redux/Sagas";

import { CookiesProvider } from "react-cookie";

const { store, persistor } = configureStore();
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
