import apiCall from "@/Utils/apiCall";

const getSnapList = ({ type, params = {} }) =>
  apiCall.get(`/api/product/${type}`, params);

const getCollection = ({ type, params }) =>
  apiCall.get(
    type !== "dvd" ? "/api/portfolio/pic" : "/api/portfolio/dvd",
    params
  );

export default {
  getSnapList,
  getCollection
};
