import { call, put, takeLatest } from 'redux-saga/effects'

import {
  REQUEST_FETCH_USER_INFO,
  SUCCESS_FETCH_USER_INFO,
  FAIL_FETCH_USER_INFO,

  REQUEST_FETCH_LOGIN,
  SUCCESS_FETCH_LOGIN,
  FAIL_FETCH_LOGIN,

  REQUEST_EDIT_USER,
  SUCCESS_EDIT_USER,
  FAIL_EDIT_USER,

  REQUEST_FETCH_LOGOUT,
} from '@/Redux/Actions/user'

import { user } from './api'

import Toast from '@/Components/Toast'

function* fetchLogin(action) {
  try {
    yield call(user.login, { email: action.email, password: action.password })

    yield put({
      type: SUCCESS_FETCH_LOGIN
    })
    
  } catch(err) {
    console.log(err)
    let msg = ''
    try {
      msg = err.msg
    } catch(err) {
      msg = '서버에러가 발생하였습니다.'
    }
    Toast.error(msg)
    
    const errMsg = (err && err.msg) ? err.msg : '이메일 혹은 비밀번호가 올바르지 않습니다.'
    yield put({
      type: FAIL_FETCH_LOGIN,
      errorMsg: errMsg,
    })
  }
}

function* fetchUserInfo(action) {
  try {
    const res = yield call(user.getUser)
   
    if (action.type === SUCCESS_FETCH_LOGIN) {
      window.location.href = '/'

    } else {
      const phone = res.phone ? res.phone : ''

      yield put({
        type: SUCCESS_FETCH_USER_INFO,
        ...res,
        phone,
      })
    }

  } catch(err) {
    console.log(err)
    yield put({
      type: FAIL_FETCH_USER_INFO
    })
  }
}

function* editUserInfo(action) {
  try {
    yield call(user.editUser, action.info)

    yield put({
      type: SUCCESS_EDIT_USER
    })
    Toast.success('내 정보를 변경하였습니다.')

  } catch(err) {
    console.log(err)
    let msg = ''
    try {
      msg = err.msg
    } catch(err) {
      msg = '서버에러가 발생하였습니다.'
    }
    Toast.error(msg)
    yield put({
      type: FAIL_EDIT_USER
    })
  }
}

function* fetchLogout() {
  try {
    yield call(user.logout)

    window.location.href = '/'

  } catch(err) {
    console.log(err)
    let msg = ''
    try {
      msg = err.msg
    } catch(err) {
      msg = '서버에러가 발생하였습니다.'
    }
    Toast.error(msg)
  }
}

export default function* watchFetchUser() {
  yield takeLatest(REQUEST_FETCH_LOGIN, fetchLogin)
  yield takeLatest([
    SUCCESS_FETCH_LOGIN,
    REQUEST_FETCH_USER_INFO
  ], fetchUserInfo)
  yield takeLatest(REQUEST_EDIT_USER, editUserInfo)
  yield takeLatest(REQUEST_FETCH_LOGOUT, fetchLogout)
}
