import { call, put, takeLatest, select } from "redux-saga/effects";

import {
  REQUEST_FETCH_LIST,
  successFetchList,
  failFetchList
} from "@/Redux/Actions/snapList";

import { snap } from "./api";

function* fetchList({ payload }) {
  const { type } = payload;
  const getFilter = state => state.filter["snap"][type];

  try {
    const filters = yield select(getFilter);
    const data = yield call(snap.getSnapList, { type, params: filters });
    yield put(successFetchList(data));
  } catch (err) {
    console.log(err);
    yield put(failFetchList());
  }
}

export default function* watchFetchList() {
  yield takeLatest([REQUEST_FETCH_LIST], fetchList);
}
