import styled from 'styled-components';

export const Icon = styled.span`
  display: block;
  width: ${({ width }) => (width ? `${width}px` : '33px')};
  height: ${({ height }) => (height ? `${height}px` : '33px')};

  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
`;
