import {
  REQUEST_FETCH_USER_INFO,
  SUCCESS_FETCH_USER_INFO,
  FAIL_FETCH_USER_INFO,

  REQUEST_FETCH_LOGIN,
  FAIL_FETCH_LOGIN,

  FAIL_FETCH_PASSWORD,
  
  CHANGE_USER_INFO,
} from '@/Redux/Actions/user'

const initialState = {
  id: '',                           // 아이디
  name: '',                         // 사용자명
  phone: '',                        // 사용자 폰번호
  isCertified: false,               // 폰번호 인증여부  사용하나?
  isLoadingUserInfo: false,         // 로딩
  isLoggedIn: false,                // 로그인여부
  errorMsg: '',                     // 로그인 실패 메시지
  showTopBanner: true,              // 최상단배너 노출 여부
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FETCH_LOGIN:
    case REQUEST_FETCH_USER_INFO:
      return {
        ...state,
        isLoadingUserInfo: true,
      }
    
    case SUCCESS_FETCH_USER_INFO:
      return {
        ...state,
        ...action,
        isLoggedIn: true,
        isLoadingUserInfo: false,
      };

    case FAIL_FETCH_PASSWORD:
    case FAIL_FETCH_LOGIN:
      return {
        ...state,
        errorMsg: action.errorMsg,
        isLoggedIn: false,
        isLoadingUserInfo: false,
      }

    case FAIL_FETCH_USER_INFO:
      return {
        ...state,
        isLoggedIn: false,
        isLoadingUserInfo: false,
      }
    
    case CHANGE_USER_INFO:
      return {
        ...state,
        ...action
      }
    
    default:
      return state
  }
}

export default user