import {
  REQUEST_POPULAR_LIST,
  SUCCESS_POPULAR_LIST,
  FAIL_POPULAR_LIST,
  REQUEST_ADBANNER,
  SUCCESS_ADBANNER,
  FAIL_ADBANNER,
  REQUEST_LIST_BANNER,
  SUCCESS_LIST_BANNER,
  FAIL_LIST_BANNER
} from '../Actions/home';

const initialState = {
  adBanner: {
    list: null,
    isLoading: true
  },

  list: {
    type: null,

    studios: null,
    magazine: null,
    events: null,

    isLoading: true
  },

  listBanner: {
    list: null,
    isLoading: true
  }
};

const homeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_POPULAR_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          type: payload.type,
          isLoading: true
        }
      };
    case SUCCESS_POPULAR_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          ...payload.data,
          isLoading: false
        }
      };
    case FAIL_POPULAR_LIST:
      return {
        ...state,
        list: {
          ...state.list,
          isLoading: false
        }
      };

    case REQUEST_ADBANNER:
      return {
        ...state,
        adBanner: {
          ...state.adBanner,
          isLoading: true
        }
      };
    case SUCCESS_ADBANNER:
      return {
        ...state,
        adBanner: {
          ...state.adBanner,
          list: payload.data,
          isLoading: false
        }
      };
    case FAIL_ADBANNER:
      return {
        ...state,
        adBanner: {
          ...state.adBanner,
          isLoaidng: false
        }
      };

    case REQUEST_LIST_BANNER:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          isLoading: true
        }
      };
    case SUCCESS_LIST_BANNER:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          list: payload.data,
          isLoading: false
        }
      };
    case FAIL_LIST_BANNER:
      return {
        ...state,
        listBanner: {
          ...state.listBanner,
          isLoaidng: false
        }
      };
    default:
      return state;
  }
};

export default homeReducer;
