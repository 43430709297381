import React from "react";

import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import styles from "@/Utils/styles";

const StyledSpinner = styled.div.attrs({
  className: "spinner"
})`
  display: flex;
  justify-content: center;
  align-items: center;

  ${ifProp(
    "absolute",
    styles.absolute,
    css`
      padding: 100px 0;
    `
  )};
`;

const Spinner = ({ innerRef, children, ...props }) => (
  <StyledSpinner ref={innerRef} {...props}>
    {children}
  </StyledSpinner>
);
React.forwardRef((props, ref) => <Spinner {...props} innerRef={ref} />);

export default Spinner;
