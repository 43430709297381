import { css } from "styled-components";
import { withProp, prop, theme } from "styled-tools";

const styles = {
  /*********************
   * BASE Styles
   */
  /**
   * 폰트 기본 스타일
   */
  baseFontStyles: css`
    margin: 0;
    padding: 0;
    font-size: ${withProp("fontSize", (fontSize) =>
      fontSize
        ? theme(`fontSize.${fontSize}`, fontSize)
        : theme("fontSize.midium")
    )};
    line-height: ${withProp("fontSize", (fontSize) =>
      fontSize
        ? theme(`lineHeight.${fontSize}`, fontSize)
        : theme("lineHeight.midium")
    )};
    font-weight: ${withProp("fontWeight", (fontWeight) =>
      fontWeight
        ? theme(`fontWeight.${fontWeight}`, fontWeight)
        : theme("fontWeight.normal")
    )};
    color: ${withProp("color", (color) =>
      color ? theme(`colors.${color}`, color) : theme("colors.grayscale.0")
    )};
  `,

  /**
   * 백그라운드 기본 스타일
   */
  baseBackgroundStyles: css`
    background-image: url(${prop("bgImage", "")});
    background-repeat: ${prop("bgRepeat", "no-repeat")};
    background-position: ${prop("bgPosition", "center")};
    background-size: ${prop("bgSize", "cover")};
    background-color: ${prop("bgColor", "transparent")};
  `,

  /**
   * 폰트 사이즈 설정
   */
  fontStyles: function(size, weight) {
    return css`
      font-size: ${theme(`fontSize.${size}`, size)};
      line-height: ${theme(`lineHeight.${size}`, size)};
      font-weight: ${theme(`fontWeight.${weight}`, weight)};
    `;
  },

  /************************
   * UTIL Styles
   */
  /**
   * float 해제
   */
  clearfix: css`
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  `,

  /**
   * 말줄임
   */
  dotdotdot: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,

  /**
   * 텍스트 가림
   */
  blind: css`
    position: absolute;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    overflow: hidden;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: -1px;
  `,

  /**
   * Absolute Position
   */
  absolute: css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
};

export default styles;
