export const SET_LIST_TYPE = "SET_LIST_TYPE";
export const REQUEST_FETCH_LIST = "REQUEST_FETCH_LIST";
export const SUCCESS_FETCH_LIST = "SUCCESS_FETCH_LIST";
export const FAIL_FETCH_LIST = "FAIL_FETCH_LIST";
export const CLEAR_SNAP_LIST = "CLEAR_SNAP_LIST";

export const INCREASE_VIEW_COUNT = "INCREASE_VIEW_COUNT";

export const setType = type => {
  return {
    type: SET_LIST_TYPE,
    payload: type
  };
};

export const requestFetchList = ({ type }) => {
  return {
    type: REQUEST_FETCH_LIST,
    payload: {
      type
    }
  };
};

export const successFetchList = data => {
  return {
    type: SUCCESS_FETCH_LIST,
    payload: {
      ...data
    }
  };
};

export const failFetchList = () => {
  return {
    type: FAIL_FETCH_LIST
  };
};

export const clearSnapList = () => {
  return {
    type: CLEAR_SNAP_LIST
  };
};

export const increaseViewCount = () => {
  return {
    type: INCREASE_VIEW_COUNT
  };
};
