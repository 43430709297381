import { all, fork } from "redux-saga/effects";

import watchFetchCommon from "./watchFetchCommon";

import {
  watchFetchPopularList,
  watchFetchAdBanner,
  watchFetchListBanner
} from "./watchFetchHome";

import watchFetchSnapList from "./watchFetchSnapList";
import watchFetchSnapCollection from "./watchFetchSnapCollection";
import watchFetchSnapWeddingHall from "./watchFetchSnapWeddingHall";

import watchFetchFavorite from "./watchFetchFavorite";

import watchFetchDetail from "./watchFetchDetail";

import watchFetchUser from "./watchFetchUser";

export default function* rootSaga() {
  yield all([
    fork(watchFetchCommon),

    fork(watchFetchPopularList),
    fork(watchFetchAdBanner),
    fork(watchFetchListBanner),

    fork(watchFetchDetail),
    fork(watchFetchSnapList),
    fork(watchFetchSnapCollection),
    fork(watchFetchSnapWeddingHall),
    fork(watchFetchFavorite),
    fork(watchFetchUser)
  ]);
}
