import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Spinner from "@/Components/Spinner";
import { Main, GlobalStyle } from "./styled";

import Header from "./Components/Header";
import Footer from "./Components/Footer";

import Routes from "./routes";

class PageLoading extends React.Component {
  state = {
    headerHeight: 0,
  };

  componentDidMount() {
    const header = document.querySelector("header");

    // header 없을 경우
    if (header) {
      const headerHeight = header.clientHeight;
      this.setState({
        headerHeight,
      });
    }
  }

  render() {
    const { headerHeight } = this.state;
    return (
      <div
        style={{
          position: "absolute",
          top: headerHeight + "px",
          left: 0,
          bottom: 0,
          width: "100%",
          background: "#fff",
          zIndex: 10,
        }}
      >
        <Spinner type="rect" />
      </div>
    );
  }
}

const isErrorPage =
  window.location.pathname === "/404" || window.location.pathname === "/500";
const Mobile = () => {
  useEffect(() => {
    if (!window.wcs_add) window.wcs_add = {};
    window.wcs_add["wa"] = "s_5117100b7ecb";
    if (!window._nasa) window._nasa = {};
    window.wcs.inflow();
    window.wcs_do(window._nasa);
  }, []);
  return (
    <Router basename="/mobile">
      <Main isErrorPage={isErrorPage}>
        <GlobalStyle />

        <Header />
        <Suspense fallback={<PageLoading />}>
          <Routes />
        </Suspense>
        <Footer />
      </Main>
    </Router>
  );
};

// const Mobile = () => <div>Mobile</div>;

export default Mobile;
