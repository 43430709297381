export const REQUEST_FETCH_USER_INFO = 'REQUEST_FETCH_USER_INFO'
export const SUCCESS_FETCH_USER_INFO = 'SUCCESS_FETCH_USER_INFO'
export const FAIL_FETCH_USER_INFO = 'FAIL_FETCH_USER_INFO'

export const REQUEST_FETCH_LOGIN = 'REQUEST_FETCH_LOGIN'
export const SUCCESS_FETCH_LOGIN = 'SUCCESS_FETCH_LOGIN'
export const FAIL_FETCH_LOGIN = 'FAIL_FETCH_LOGIN'

export const REQUEST_FETCH_PASSWORD = 'REQUEST_FETCH_PASSWORD'
export const SUCCESS_FETCH_PASSWORD = 'SUCCESS_FETCH_PASSWORD'
export const FAIL_FETCH_PASSWORD = 'FAIL_FETCH_PASSWORD'

export const REQUEST_EDIT_USER = 'REQUEST_EDIT_USER'
export const SUCCESS_EDIT_USER = 'SUCCESS_EDIT_USER'
export const FAIL_EDIT_USER = 'FAIL_EDIT_USER'

export const REQUEST_FETCH_LOGOUT = 'REQUEST_FETCH_LOGOUT'
export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'

export const login = (email, password) => ({
  type: REQUEST_FETCH_LOGIN,
  email,
  password,
})

export const forgotPassword = (email) => ({
  type: REQUEST_FETCH_PASSWORD,
  email
})

export const fetchUserInfo = () => ({
  type: REQUEST_FETCH_USER_INFO
})

export const editUserInfo = (info) => ({
  type: REQUEST_EDIT_USER,
  info,
})

export const changeUserInfo = (info) => ({
  type: CHANGE_USER_INFO,
  ...info
})

export const logout = () => ({
  type: REQUEST_FETCH_LOGOUT,
})