import { combineReducers } from "redux";

import home from "./homeReducer";

import snapList from "./snapListReducer";
import snapCollection from "./snapCollectionReducer";
import snapWeddingHall from "./snapWeddingHallReducer";
import snapSearchFilter from "./snapSearchFilter";
import snapViewer from "./snapViewerReducer";

import favorite from "./favoriteReducer";

import filter from "./filterReducer";
import detail from "./detailReducer";

import user from "./user";

import safetyPayment from "./safetyPaymentReducer";
import planReducer from "./planReducer";

const rootReducer = combineReducers({
  home,

  snapList,
  snapCollection,
  snapWeddingHall,
  snapSearchFilter,
  snapViewer,

  favorite,

  filter,
  detail,

  user,
  safetyPayment,
  planReducer,
});

export default rootReducer;
