import React, { useState, useEffect, useRef } from 'react';

import { BalloonWrapper, CloseButton } from './styled';
const Balloon = ({ children, ...props }) => {
  const [isShow, setIsShow] = useState(true);
  const balloonRef = useRef(null);
  let clearTimer = null;
  let fadeOutTimer = null;

  useEffect(() => {
    if (isShow) {
      closeAnimation({
        fadeDelay: 6000,
        clearDelay: 6500
      });
    }

    return () => {
      allClearTimer();
    };
  }, []);

  const allClearTimer = () => {
    clearTimeout(fadeOutTimer);
    clearTimeout(clearTimer);
  };
  const closeAnimation = ({ fadeDelay, clearDelay }) => {
    fadeOutTimer = setTimeout(() => {
      if (balloonRef.current != null) {
        balloonRef.current.classList.add('fade-out');
      }
    }, fadeDelay);

    clearTimer = setTimeout(() => {
      setIsShow(false);
    }, clearDelay);
  };

  const onCloseHandler = () => {
    allClearTimer();
    closeAnimation({
      fadeDelay: 0,
      clearDelay: 500
    });
  };

  if (!isShow) {
    return null;
  }

  return (
    <BalloonWrapper ref={balloonRef} {...props}>
      {children}
      <CloseButton onClick={onCloseHandler} />
    </BalloonWrapper>
  );
};

export default Balloon;
