import styled, { keyframes } from "styled-components";
import closeSvg from "./images/icon-close.svg";

const FadeIn = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

const FadeOut = keyframes`
  from {
    opacity: 1
  }
  to {
    opacity: 0
  }
`;

export const BalloonWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 4px 15px 4px;
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  color: #dd4c5c;
  border-radius: 999px;
  border: solid 1px #dd4c5c;
  background-color: #fff5f8;

  animation: ${FadeIn} 0.5s forwards;

  &.fade-out {
    animation: ${FadeOut} 0.5s forwards;
  }
`;

export const CloseButton = styled.button.attrs({
  type: "button"
})`
  display: block;
  position: absolute;
  top: 11px;
  right: 8px;
  width: 10px;
  height: 10px;
  border: 0;
  background: url(${closeSvg}) center 100% no-repeat;
`;
