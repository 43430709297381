export const REQUEST_POPULAR_LIST = 'REQUEST_HOME_LIST';
export const SUCCESS_POPULAR_LIST = 'SUCCESS_POPULAR_LIST';
export const FAIL_POPULAR_LIST = 'FAIL_POPULAR_LIST';

export const REQUEST_ADBANNER = 'REQUEST_ADBANNER';
export const SUCCESS_ADBANNER = 'SUCCESS_ADBANNER';
export const FAIL_ADBANNER = 'FAIL_ADBANNER';

export const REQUEST_LIST_BANNER = 'REQUEST_LIST_BANNER';
export const SUCCESS_LIST_BANNER = 'SUCCESS_LIST_BANNER';
export const FAIL_LIST_BANNER = 'FAIL_LIST_BANNER';

export const requestPopularList = type => {
  return {
    type: REQUEST_POPULAR_LIST,
    payload: {
      type
    }
  };
};
export const successPopularList = data => {
  return {
    type: SUCCESS_POPULAR_LIST,
    payload: {
      data
    }
  };
};
export const failPopularList = () => {
  return {
    type: FAIL_POPULAR_LIST
  };
};

export const requestAdBanner = () => {
  return {
    type: REQUEST_ADBANNER
  };
};

export const successAdBanner = data => {
  return {
    type: SUCCESS_ADBANNER,
    payload: {
      data
    }
  };
};

export const failAdBanner = () => {
  return {
    type: FAIL_ADBANNER
  };
};

export const requestListBanner = () => {
  return {
    type: REQUEST_LIST_BANNER
  };
};

export const successListBanner = data => {
  return {
    type: SUCCESS_LIST_BANNER,
    payload: {
      data
    }
  };
};

export const failListBanner = () => {
  return {
    type: FAIL_LIST_BANNER
  };
};
