import React from 'react';

import { Loading } from './styled';

const Dot = ({ width, height, color }) => {
  return (
    <Loading width={width} height={height} color={color}>
      <div className='bounce1'></div>
      <div className='bounce2'></div>
      <div className='bounce3'></div>
    </Loading>
  );
};

export default Dot;
