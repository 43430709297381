import React from "react";
import { withRouter, Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import { logout } from "@/Redux/Actions/user";
import { clearSnapList } from "@/Redux/Actions/snapList";
import Icons from "@/ViewMobile/Components/_Basic/Icon";

import {
  Logo,
  LogoWrap,
  Wrap,
  Menu,
  Overlay,
  SignWrap,
  Sign,
  HrLine,
  SubMenu,
  SubMenuList,
  EstimateBox,
  IconList,
  Icon,
  Name,
  WishListButton,
  Div,
  OuterLink,
  AuthButtonGroup,
  LinkButton,
  Button,
  SnapTitle,
  SnapButtonGroup,
  SnapButton,
  SnapThumbnail,
  ServiceBox,
  Line,
} from "./styled";
import withController from "./Controller";

const SliderComponent = ({
  name,
  isLoggedIn,
  handler,
  onTransition,
  logout,
  clearSnapList,
}) => {
  const onClickLink = () => {
    handler();
    clearSnapList();
  };
  return (
    <Wrap>
      <Overlay onClick={handler} />
      <Menu>
        <SignWrap>
          <LogoWrap>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </LogoWrap>
          {isLoggedIn && (
            <Sign>
              <SubMenuList>
                <Name>{name}</Name> 님
                <WishListButton to="/favorite">위시리스트</WishListButton>
              </SubMenuList>
            </Sign>
          )}
        </SignWrap>

        <SubMenu>
          <SnapTitle>촬영 카테고리</SnapTitle>
          <SubMenuList onClick={onTransition("/main")}>본식스냅</SubMenuList>
          <SubMenuList onClick={onTransition("/dvd")}>본식DVD</SubMenuList>
          <SubMenuList onClick={onTransition("/jeju")}>제주스냅</SubMenuList>
          <SubMenuList onClick={onTransition("/simple")}>심플스튜디오</SubMenuList>
          <SubMenuList onClick={onTransition("/dme")}>촬영 드레스·메이크업샵</SubMenuList>
          <Line />
          <SnapTitle>유용한 기능</SnapTitle>

          <EstimateBox to={"/estimate"}>
            <img src={(require('@/ViewMobile/images/m-home-est-banner.png'))} alt="" />
          </EstimateBox>
        </SubMenu>

        <SubMenu>
          <SubMenuList onClick={onTransition("/event")}><span>이벤트</span></SubMenuList>
          <SubMenuList onClick={onTransition("/magazine")}><span>매거진</span></SubMenuList>
          <SubMenuList>
            <a href="https://blog.naver.com/fddingofficial/222157664820" target="_blank" rel="noopener noreferrer">촬영 표준계약서 배포</a>
          </SubMenuList>
          <SubMenuList onClick={onTransition("/about")}><span>회사소개</span></SubMenuList>
          <Line />
        </SubMenu>
        <IconList>
          <OuterLink href="https://membership.fdding.com">
            <img src={require("../../images/img-membership.png")} alt="" />
          </OuterLink>
        </IconList>

        <AuthButtonGroup>
          {isLoggedIn ? (
            <>
              <Button onClick={logout} padding="9px 27px">
                로그아웃
              </Button>
              <LinkButton to="/account" margin="0 0 0 12px" padding="9px 17px">
                내 정보 변경
              </LinkButton>
            </>
          ) : (
            <>
              <LinkButton to="/signin">로그인</LinkButton>
              <LinkButton to="/signup" padding="9px 27px">
                회원가입
              </LinkButton>
            </>
          )}
        </AuthButtonGroup>
      </Menu>
    </Wrap>
  );
};

export default compose(
  withRouter,
  connect(
    ({ user: { name, isLoggedIn } }) => ({
      name,
      isLoggedIn,
    }),
    { logout, clearSnapList }
  ),
  withController
)(SliderComponent);
