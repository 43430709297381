export const REQUEST_FAVORITE_LIST = 'REQUEST_FAVORITE_LIST';
export const SUCCESS_FAVORITE_LIST = 'SUCCESS_FAVORITE_LIST';
export const FAIL_FAVORITE_LIST = 'FAIL_FAVORITE_LIST';

export const requestFavoriteList = () => {
  return {
    type: REQUEST_FAVORITE_LIST
  };
};

export const successFavoriteList = data => {
  return {
    type: SUCCESS_FAVORITE_LIST,
    payload: {
      data
    }
  };
};

export const failFavoriteList = () => {
  return {
    type: FAIL_FAVORITE_LIST
  };
};
