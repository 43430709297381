import {
  REQUEST_SNAP_COLLECTION,
  SUCCESS_SNAP_COLLECTION,
  FAIL_SNAP_COLLECTION,
  CLEAR_SNAP_COLLECTION
} from "../Actions/snapCollection";

const initialState = {
  currentType: null,
  isLoading: true,

  hasMore: false,
  items: [],
  nextPage: 1,
  page: 1,
  limit: 30
};

const listCollectReducder = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_SNAP_COLLECTION:
      return {
        ...state,
        currentType: payload.type,
        isLoading: true
      };
    case SUCCESS_SNAP_COLLECTION:
      return {
        ...state,
        ...payload.data,
        items: [...state.items, ...payload.data.items],
        isLoading: false
      };
    case FAIL_SNAP_COLLECTION:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_SNAP_COLLECTION:
      return initialState;
    default:
      return state;
  }
};

export default listCollectReducder;
