import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const ViaMobile = ({ ...rest }) => {
  const {
    location: { pathname },
  } = rest;

  const [replacePathname, setReplacePathname] = useState(null);

  useEffect(() => {
    try {
      if (pathname.match(/mobile/)) {
        setReplacePathname(pathname.replace("/mobile", ""));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  if (replacePathname) {
    return <Redirect to={replacePathname} />;
  }

  return <Route {...rest} render={(props) => <div>Via Mobile</div>} />;
};

export default ViaMobile;
