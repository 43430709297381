import {
  SELECT_ITEM,
  SELECT_ITEM_INDEX,
  SET_SNAP_TYPE,
  SET_VIEWER_TYPE,
  CLEAR_VIEWER,
} from "../Actions/snapViewer";

const initialState = {
  type: null,
  viewerType: null,
  currentPhoto: null,
  currentIndex: null,
};

const viewerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SELECT_ITEM:
      return {
        ...state,
        currentPhoto: payload.data,
      };
    case SELECT_ITEM_INDEX:
      return {
        ...state,
        currentIndex: payload.index,
      };
    case SET_SNAP_TYPE:
      return {
        ...state,
        type: payload.type,
      };
    case SET_VIEWER_TYPE:
      return {
        ...state,
        viewerType: payload.viewerType,
      };
    case CLEAR_VIEWER:
      return initialState;
    default:
      return state;
  }
};

export default viewerReducer;
