export const REQUEST_FETCH_DETAIL = 'REQUEST_FETCH_DETAIL';
export const SUCCESS_FETCH_DETAIL = 'SUCCESS_FETCH_DETAIL';
export const FAIL_FETCH_DETAIL = 'FAIL_FETCH_DETAIL';

export const REQUEST_PORTFOLIO = 'REQUEST_PORTFOLIO';
export const SUCCESS_PORTFOLIO = 'SUCCESS_PORTFOLIO';
export const FAIL_PORTFOLIO = 'FAIL_PORTFOLIO';

export const fetchDetail = ({ type, seq }) => ({
  type: REQUEST_FETCH_DETAIL,
  payload: {
    type,
    seq
  }
});

export const requestPortfolio = ({ type, enterpriseId }) => {
  return {
    type: REQUEST_PORTFOLIO,
    payload: {
      type,
      enterpriseId
    }
  };
};

export const successPortfolio = data => {
  return {
    type: SUCCESS_PORTFOLIO,
    payload: {
      data
    }
  };
};

export const failPortfolio = () => {
  return {
    type: FAIL_PORTFOLIO
  };
};
