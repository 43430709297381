import { call, put, takeLatest } from 'redux-saga/effects';

import {
  REQUEST_FETCH_DETAIL,
  SUCCESS_FETCH_DETAIL,
  FAIL_FETCH_DETAIL,
  REQUEST_PORTFOLIO,
  successPortfolio,
  failPortfolio
} from '@/Redux/Actions/detail';

import { detail } from './api';
import { IMG_URL } from '@/config';

function convertImgPath(arr = [], url, mid) {
  if (arr === null) return [];
  return arr.reduce((r, i) => {
    const newPath = `${url}/${mid}/${i}`;
    r.push(newPath);
    return r;
  }, []);
}

function* fetchDetail({ payload }) {
  const { type, seq } = payload;

  try {
    const size = navigator.userAgent.indexOf('Mobile') - 1 ? '/1920' : '/640';
    const res = yield call(detail.getDetail, { type, seq });

    const images = res.images
      ? convertImgPath(res.images, IMG_URL, `${type}${size}`)
      : null;
    const etcImg = res.etcImg
      ? convertImgPath(res.etcImg, IMG_URL, 'etc')
      : null;
    const albumImg = res.albumImg
      ? convertImgPath(res.albumImg, IMG_URL, 'album')
      : null;
    const reviewImg = res.reviewImg
      ? convertImgPath(res.reviewImg, IMG_URL, 'review')
      : null;

    yield put({
      type: SUCCESS_FETCH_DETAIL,
      payload: {
        detail: {
          ...res,
          images,
          etcImg,
          albumImg,
          reviewImg
        }
      }
    });
  } catch (err) {
    console.log(err);
    let msg = '';
    try {
      msg = err.msg;
    } catch (err) {
      msg = '서버에러가 발생하였습니다.';
    }

    window.alert(msg);
    window.location.href = `/${type}`;

    yield put({
      type: FAIL_FETCH_DETAIL
    });
  }
}

export function* fetchPortfolio({ payload }) {
  const { type, enterpriseId } = payload;

  try {
    const data = yield call(detail.getPortfolio, { type, enterpriseId });

    yield put(successPortfolio(data));
  } catch (e) {
    yield put(failPortfolio());
  }
}

export default function* watchFetchDetail() {
  yield takeLatest(REQUEST_FETCH_DETAIL, fetchDetail);
  yield takeLatest(REQUEST_PORTFOLIO, fetchPortfolio);
}
