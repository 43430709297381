// 지역
export const LOCATION = [
  {
    key: 0,
    name: "서울특별시"
  },
  {
    key: 1,
    name: "인천광역시"
  },
  {
    key: 2,
    name: "경기남부",
    tail: "(수원/용인/화성/안산/성남 등)"
  },
  {
    key: 3,
    name: "경기북부",
    tail: "(고양/구리/의정부 등)"
  },
  {
    key: 15,
    name: "부산광역시"
  },
  {
    key: 8,
    name: "대구광역시"
  },
  {
    key: 5,
    name: "대전광역시"
  },
  {
    key: 11,
    name: "광주광역시"
  },
  {
    key: 16,
    name: "세종특별시"
  },
  {
    key: 14,
    name: "울산광역시"
  },
  {
    key: 4,
    name: "강원도"
  },
  {
    key: 7,
    name: "충청북도"
  },
  {
    key: 6,
    name: "충청남도"
  },
  {
    key: 10,
    name: "경상북도"
  },
  {
    key: 9,
    name: "경상남도"
  },
  {
    key: 12,
    name: "전라북도"
  },
  {
    key: 13,
    name: "전라남도"
  },
  {
    key: 17,
    name: "제주도"
  }
];

export const REGION = [
  { label: "서울특별시", name: "서울", type: 2, value: 0 },
  { label: "인천광역시", name: "인천광역시", type: 2, value: 1 },
  {
    label: "경기남부(수원/용인/화성/안산/성남 등)",
    name: "경기남부",
    type: 1,
    value: 2
  },
  {
    label: "경기북부(고양/구리/의정부 등)",
    name: "경기북부",
    type: 1,
    value: 3
  },
  { label: "부산광역시", name: "부산광역시", type: 2, value: 15 },
  { label: "광주광역시", name: "광주광역시", type: 2, value: 11 },
  { label: "대전광역시", name: "대전광역시", type: 2, value: 5 },
  { label: "대구광역시", name: "대구광역시", type: 2, value: 8 },
  { label: "울산광역시", name: "울산광역시", type: 2, value: 14 },
  { label: "강원도", name: "강원도", type: 2, value: 4 },
  { label: "충청북도", name: "충청북도", type: 2, value: 7 },
  { label: "충청남도", name: "충청남도", type: 2, value: 6 },
  { label: "전라북도", name: "전라북도", type: 2, value: 12 },
  { label: "전라남도", name: "전라남도", type: 2, value: 13 },
  { label: "경상북도", name: "경상북도", type: 2, value: 10 },
  { label: "경상남도", name: "경상남도", type: 2, value: 9 },
  { label: "세종특별시", name: "세종특별시", type: 2, value: 16 },
  { label: "제주도", name: "제주도", type: 2, value: 17 }
];

export const RecFilter = {
  jeju: [
    { label: "드론 촬영", type: 2, name: "shootDrone" },
    { label: "야간 촬영", type: 2, name: "shootNight" },
    { label: "실내 촬영", type: 2, name: "shootIndoor" },
    { label: "커스텀 촬영", type: 2, name: "shootCustom" },
    { label: "한복 촬영", type: 2, name: "shootHanbok" },
    { label: "2인 작가 촬영", type: 2, name: "shootDuo" }
  ],
  simple: [
    { label: "드레스 포함", type: 2, name: "includeDress" },
    { label: "메이크업 포함", type: 2, name: "includeMakeup" },
    { label: "한복 포함", type: 2, name: "includeHanbok" },
    { label: "2인 작가 촬영", type: 2, name: "shootDuo" },
    { label: "야외 촬영 세트장 보유", type: 1, name: "includeOutdoor" }
  ],
  dme: [
    { label: "드레스", type: 2, name: "isDress" },
    { label: "메이크업", type: 2, name: "isMakeup" },
    { label: "헤어", type: 2, name: "isHair" },
    { label: "한복", type: 2, name: "isHanbok" }
  ]
};

export const ModeFilter = {
  main: [
    {
      label: "웨딩홀/컨벤션",
      name: "convention",
      type: 1
    },
    {
      label: "호텔",
      name: "hotel",
      type: 1
    },
    {
      label: "하우스/야외/전통/채플",
      name: "house",
      type: 1
    }
  ]
};

export const CharFilter = {
  main: [
    {
      label: "#세피아톤",
      name: "toneSepia"
    },
    {
      label: "#화이트톤",
      name: "toneWhite"
    },
    {
      label: "#피치톤",
      name: "tonePeach"
    },
    {
      label: "#화사한색감",
      name: "gorgeous"
    },
    {
      label: "#편안한색감",
      name: "comfy"
    },
    {
      label: "#선명한색감",
      name: "vivid"
    },
    {
      label: "#고전적인클래식",
      name: "classic"
    },
    {
      label: "#빈티지보정",
      name: "vintage"
    },
    {
      label: "#드라마틱",
      name: "dramatic"
    },
    {
      label: "#따뜻한분위기",
      name: "moodWarm"
    }
  ],
  dvd: [
    // toneWhite: false, // 화이트톤
    // toneMono: false, // 흑백톤
    // toneGray: false, // 그레이톤
    // toneVivid: false, // 선명한톤
    // toneVintage: false // 빈티지톤
    {
      label: "#화이트톤",
      name: "toneWhite"
    },
    {
      label: "#흑백톤",
      name: "toneMono"
    },
    {
      label: "#그레이톤",
      name: "toneGray"
    },
    {
      label: "#선명한톤",
      name: "toneVivid"
    },
    {
      label: "#빈티지톤",
      name: "toneVintage"
    }
  ],
  jeju: [
    {
      label: "#화보형",
      name: "pictorial"
    },
    {
      label: "#클래식",
      name: "classic"
    },
    {
      label: "#트렌디",
      name: "trendy"
    },
    {
      label: "#따뜻한",
      name: "warm"
    },
    {
      label: "#빈티지",
      name: "vintage"
    },
    {
      label: "#편안한",
      name: "comfy"
    },
    {
      label: "#특별한",
      name: "special"
    },
    {
      label: "#선명한",
      name: "vivid"
    },
    {
      label: "#러블리",
      name: "lovely"
    },
    {
      label: "#로맨틱",
      name: "romantic"
    },
    {
      label: "#깨끗한",
      name: "clean"
    },
    {
      label: "#드라마틱",
      name: "dramatic"
    }
  ],
  simple: [
    {
      label: "#심플한",
      name: "simple"
    },
    {
      label: "#트렌디",
      name: "trendy"
    },
    {
      label: "#러블리",
      name: "lovely"
    },
    {
      label: "#빈티지",
      name: "vintage"
    },
    {
      label: "#화보형",
      name: "pictorial"
    },
    {
      label: "#클래식",
      name: "classic"
    },
    {
      label: "#흑백사진",
      name: "monochrome"
    }
  ]
};

export const AddFilter = {
  gallery: [
    {
      label: "인화 앨범 비치 중",
      name: "albumType"
    },
    {
      label: "디스플레이 전시 중",
      name: "displayType"
    },
    {
      label: "갤러리 방문고객 할인",
      name: "visitor"
    }
  ],
  certification: [
    {
      label: "사업자등록증 인증",
      name: "isEnterprise"
    }
  ]
};

export const QualityFilter = {
  dvd: [
    {
      label: "4k",
      name: "quality4k"
    },
    {
      label: "fullHD",
      name: "qualityFullHd"
    },
    {
      label: "UHD",
      name: "qualityUhd"
    }
  ]
};

export const FilterData = {
  edit: {
    dvd: [
      {
        label: "챕터별 편집",
        name: "editChapter"
      },
      {
        label: "시네마틱",
        name: "cinematic"
      },
      {
        label: "M/V형",
        name: "styleMv"
      },
      {
        label: "다큐형",
        name: "styleDocu"
      },
      {
        label: "하이라이트형",
        name: "styleHighlight"
      }
    ]
  },

  quality: {
    dvd: [
      {
        label: "4K",
        name: "quality4k"
      },
      {
        label: "UHD",
        name: "qualityUhd"
      },
      {
        label: "Full HD",
        name: "qualityFullHd"
      }
    ]
  }
};

export const InitMainFilter = {
  region: {
    standardRegion: null
  },

  // mode: {
  //   convention: false, //웨딩홀/컨벤션
  //   hotel: false, // 호텔
  //   house: false //하우스/야외/전통
  // },

  char: {
    toneSepia: false, // 세피아톤
    toneWhite: false, // 화이트톤
    tonePeach: false, // 피치톤
    vivid: false, // 선명한색감
    moodWarm: false, // 따뜻한 분위기
    comfy: false, // 편안한색감
    gorgeous: false, // 화사한색감
    classic: false, // 고전적인클래식
    dramatic: false, // 드라마틱
    vintage: false // 빈티지보정
  }

  // price: {
  //   priceFrom: 0,
  //   priceTo: 999999
  // },

  // add: {
  //   albumType: false, //인화 앨범 비치중
  //   displayType: false, //디스플레이 전시중
  //   visitor: false, // 갤러리 방문고객 할인
  //   isEnterprise: false //사업자등록증 인증
  // }
};

export const InitMainDvdFilter = {
  region: {
    region: null // 지역
  },

  char: {
    toneWhite: false, // 화이트톤
    toneMono: false, // 흑백톤
    toneGray: false, // 그레이톤
    toneVivid: false, // 선명한톤
    toneVintage: false // 빈티지톤
  }
};

export const InitMainDvdCollectionFilter = {
  edit: {
    editChapter: false, // 챕터별 편집,
    cinematic: false, // 시네마틱
    styleDocu: false, // 다큐형
    styleHighlight: false, // 하이라이트형
    styleMv: false // M/V형
  },
  quality: {
    quality4k: false, // 4k
    qualityFullHd: false, // fullHD
    qualityUhd: false // UHD
  }
};

export const InitJejuFilter = {
  rec: {
    shootDrone: false, //드론촬영
    shootNight: false, //야간촬영
    shootIndoor: false, //실내촬영
    shootCustom: false, //커스텀촬영
    shootHanbok: false, //한복촬영
    shootDuo: false //2인작가 촬영
  },

  char: {
    pictorial: false, // 화보형
    classic: false, // 클래식
    trendy: false, // 트렌디
    special: false, // 특별한
    vivid: false, // 선명한
    dramatic: false, // 드라마틱
    lovely: false, // 러블리
    romantic: false, // 로맨틱
    warm: false, // 따뜻한
    vintage: false, // 빈티지
    comfy: false, // 편안한
    clean: false // 깨끗한
  }

  // price: {
  //   priceFrom: 0,
  //   priceTo: 999999
  // },

  // add: {
  //   albumType: false, //인화 앨범 비치중
  //   displayType: false, //디스플레이 전시중
  //   visitor: false, // 갤러리 방문고객 할인
  //   isEnterprise: false //사업자등록증 인증
  // }
};

export const InitSimpleFilter = {
  // region: {
  //   standardRegion: null
  // },

  rec: {
    includeDress: false, // 드레스 포함
    includeMakeup: false, // 메이크업 포함
    includeHanbok: false, // 한복 포함
    shootDuo: false, // 2인 작가 촬영
    includeOutdoor: false // 야외 촬영 세트장 보유
  },

  char: {
    simple: false, // 심플한
    trendy: false, // 트렌디
    lovely: false, // 러블리
    vintage: false, // 빈티지
    pictorial: false, // 화보형
    classic: false, // 클래식
    monochrome: false // 흑백사진
  }

  // add: {
  //   albumType: false, //인화 앨범 비치중
  //   displayType: false, //디스플레이 전시중
  //   visitor: false, // 갤러리 방문고객 할인
  //   isEnterprise: false //사업자등록증 인증
  // }
};

export const InitDmeFilter = {
  region: {
    region: null
  },

  rec: {
    isDress: false, //드레스
    isMakeup: false, //메이크업
    isHair: false, //헤어
    isHanbok: false
  }
};

export const Filters = {
  snap: {
    main: InitMainFilter,
    dvd: InitMainDvdFilter,
    jeju: InitJejuFilter,
    simple: InitSimpleFilter,
    dme: InitDmeFilter
  },

  collection: {
    dvd: InitMainDvdCollectionFilter
  }
};
