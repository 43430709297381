import {
  FAIL_FETCH_LIST,
  REQUEST_FETCH_LIST,
  SUCCESS_FETCH_LIST,
  SET_LIST_TYPE,
  CLEAR_SNAP_LIST,
  INCREASE_VIEW_COUNT
} from "@/Redux/Actions/snapList";

const initialState = {
  type: null,
  isLoading: false,
  premium: null,
  special: null,
  master: null,
  viewCount: 0
};

const listReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LIST_TYPE:
      return {
        ...state,
        type: payload
      };
    case REQUEST_FETCH_LIST:
      return {
        ...state,
        list: null,
        isLoading: true,
        premium: null,
        special: null,
        master: null
      };
    case SUCCESS_FETCH_LIST:
      return {
        ...state,
        ...payload,
        isLoading: false
      };
    case FAIL_FETCH_LIST:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_SNAP_LIST:
      return {
        ...initialState,
        viewCount: state.viewCount
      };
    case INCREASE_VIEW_COUNT:
      return {
        ...state,
        viewCount: state.viewCount + 1
      };
    default:
      return state;
  }
};

export default listReducer;
