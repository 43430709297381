import {
  CHANGE_LIST_KEYWORD,
  CLEAR_LIST_KEYWORD,
  CHANGE_WEDDINGHALL_KEYWORD,
  CLEAR_WEDDINGHALL_KEYWORD
} from '../Actions/snapSearchFilter';

const initialState = {
  listKeyword: '',
  weddingHallKeyword: ''
};

const snapSearchFilter = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_LIST_KEYWORD:
      return {
        ...state,
        listKeyword: payload.input
      };
    case CLEAR_LIST_KEYWORD:
      return {
        ...state,
        listKeyword: ''
      };

    case CHANGE_WEDDINGHALL_KEYWORD:
      return {
        ...state,
        weddingHallKeyword: payload.input
      };
    case CLEAR_WEDDINGHALL_KEYWORD:
      return {
        ...state,
        weddingHallKeyword: ''
      };
    default:
      return state;
  }
};

export default snapSearchFilter;
