import React from 'react';
import { render } from 'react-dom';

import Container from './Container';
import Msg from './Message';

import { isMobile } from '@/Utils';
import { generateUUID } from './utils';

class Toast {
  constructor() {
    this.state = {
      options: {
        containerId: 'react-toast',
        newestOnTop: true, // ture: 새로운거 맨위에 삽입, false: 새로운거 맨아래에 삽입.
        position: 'top-right',
        timeout: 5000,
      },

      toastType: {
        info: 'info',
        success: 'success',
        error: 'error',
      },

      position: {
        top_left: 'top-left',
        top_center: 'top-center',
        top_right: 'top-right',
        bottom_left: 'bottom-left',
        bottom_center: 'bottom-center',
        bottom_right: 'bottom-right',
      }
    }
  }

  version = '1.0.0'

  info = (message, title) => this.createToast({
    type: this.state.toastType.info,
    title: title || 'INFO',
    message,
  });

  success = (message, title) => this.createToast({
    type: this.state.toastType.success,
    title: title || 'SUCCESS',
    message,
  });

  error = (message, title) => this.createToast({
    type: this.state.toastType.error,
    title: title || 'ERROR',
    message,
  });

  createContainer = (options = {}) => {
    const option = Object.assign({}, this.state.options, options);
    const div = document.createElement('div');

    div.setAttribute('id', 'toast');
    document.body.appendChild(div);

    render(<Container options={option} />, div)
  }

  createToast = obj => {
    const { newestOnTop, timeout } = this.state.options;

    const toastInfo = {
      id: generateUUID(),
      timeout: timeout,
      ...obj,
    }

    if (isMobile.any()) {
      return alert(obj.message)
    }

    const temp = document.createElement('div');
    temp.setAttribute('class', `toast ${toastInfo.type}`);
    temp.setAttribute('id', toastInfo.id);
    const container = document.getElementById('react-toast')

    render(<Msg data={toastInfo} />, temp);

    if (newestOnTop) {
      container.insertBefore(temp, container.firstChild);
    } else {
      container.appendChild(temp);
    }
  }
}


export default new Toast();
