import styled from "styled-components";
import { Link as BasicLink } from "react-router-dom";
import { Logo as BasicLogo } from "../../styled";

export const Wrap = styled.div`
  top: 0;
  left: 0;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

export const Menu = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 274px;
  background: #f8f8f8;
  z-index: 1001;
  overflow-y: auto;
`;

export const SignWrap = styled.div`
  width: 100%;
  background: #fff;
`;

export const LogoWrap = styled.div`
  position: relative;
  height: 50px;
`;

export const Logo = styled(BasicLogo)`
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
`;

export const Sign = styled.div`
  min-height: 50px;
`;

export const List = styled.ul`
  padding: 0 16px;
  font-size: 14px;
  font-weight: 300;
  line-height: 50px;
  color: #333;
  li {
    display: inline-block;
    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

export const HrLine = styled.div`
  width: 100%;
  height: 7px;
  background-color: #f8f8f8;
`;

export const SubMenu = styled.div`
  background: #fff;
`;

export const SubMenuList = styled.div`
  position: relative;
  height: 42px;
  padding: 13px 0 13px 17px;
  line-height: 15.6px;
  font-size: 13px;
  color: #242424;
  > span, a {
    font-weight: bold;
    color: #242424;
  }

  > img {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
`;

export const EstimateBox = styled(BasicLink).attrs({
  className: "estimate-box",
})`
  display: block;
  width: 100%;
  > img {
    width: 100%;
  }

  .estimate-box__inner {
    padding: 16px 0 14px;
    text-align: center;
    background-color: rgba(194, 151, 120, 0.1);
  }

  .estimate-box__title {
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    color: #333;

    .estimate-box__icon {
      margin-right: 6px;
    }

    .estimate-box__text {
      vertical-align: middle;
    }
  }

  .estimate-box__desc {
    padding: 0 33px;
    font-size: 11px;
    margin-top: 7px;
    line-height: normal;
    text-align: center;
    color: #333;
    word-break: keep-all;
  }
`;

export const SnapTitle = styled.h2`
  padding: 12px 0 13px 17px;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: left;
  color: #242424;
`;

export const SnapThumbnail = styled.img`
  display: block;
  width: 55px;
  height: 55px;
  margin: 0 auto 8px;
  object-fit: contain;
`;
export const SnapButton = styled(BasicLink)`
  display: inline-block;
  vertical-align: top;
  width: 55px;

  font-size: 10px;
  line-height: normal;
  text-align: center;
  color: #333;

  > span {
    word-break: keep-all;
  }
`;

export const SnapButtonGroup = styled.div`
  overflow: hidden;
  padding: 0 16px 30px 19px;
  text-align: center;

  > span {
    display: block;
    margin-bottom: 20px;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
  }

  ${SnapButton} + ${SnapButton} {
    margin-left: 9px;
  }

  &.plan-list {
    ${SnapButton} + ${SnapButton} {
      margin-left: 28px;
    }
  }

  .line-braker {
    margin-top: 8px
  }
`;

export const Name = styled.span`
  font-weight: 700;
`;

export const WishListButton = styled(BasicLink)`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  font-weight: 300;
  font-size: 12px;
  line-height: 1.08;
  color: #333;
`;

export const AuthButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background: transparent;
`;

export const LinkButton = styled(BasicLink)`
  display: block;
  width: 100%;
  margin-left: 12px;
  padding: 9px 0;
  font-size: 12px;
  line-height: normal;
  text-align: center;
  color: #a3a3a3;
  border-radius: 4px;
  border: solid 1px #a3a3a3;
  background-color: #ffffff;

  &:first-child {
    margin-left: 0;
  }
`;
export const Button = LinkButton.withComponent("button");

export const OuterLink = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  display: inline-block;
  margin-left: 10px;
  font-size: 12px;
  color: #a3a3a3;
  line-height: 1;
  vertical-align: middle;

  > img {
    width: 164px;
    margin: 27px 79px 27px 11px;
  }
`;

export const Icon = styled.div`
  padding: 15px 20px;

  &:first-child {
    margin-top: 0;
  }
`;

export const IconList = styled.div`
background: #fff;
`;

export const ServiceBox = styled.div`
  background: #fff;
`;

export const Div = styled.div`
  display: inline-block;
  width: ${({ width }) => (width ? `${width}px` : "20px")};
  height: ${({ height }) => (height ? `${height}px` : "20px")};
  border-radius: 100%;
  background-image: url(${(props) => props.imgUrl});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: middle;
`;

export const Line = styled.div`
  width: 274px;
  height: 0px;
  margin-top: 10px;
  border-bottom: 1px solid #ECECEC;
  flex: none;
  order: 2;
  flex-grow: 0;
`;
