import { CHANGE_FILTER, RESET_FILTER } from "../Actions/filter";

import { Filters } from "@/App/constants/filters";

import { spreadObj } from "@/Utils";

const initialState = {
  // 업체 리스트 필터
  snap: {
    main: {
      ...spreadObj(Filters["snap"]["main"]),
    },
    dvd: {
      ...spreadObj(Filters["snap"]["dvd"]),
    },
    jeju: {
      ...spreadObj(Filters["snap"]["jeju"]),
    },
    simple: {
      ...spreadObj(Filters["snap"]["simple"]),
    },
    dme: {
      ...spreadObj(Filters["snap"]["dme"]),
    },
  },

  // 콜렉션 전용 필터 추가
  collection: {
    dvd: { ...spreadObj(Filters["collection"]["dvd"]) },
  },
};

const filterReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case CHANGE_FILTER:
      return {
        ...state,
        [payload.listType]: {
          ...state[payload.listType],
          [payload.type]: {
            ...payload.filters,
          },
        },
      };
    case RESET_FILTER:
      return {
        ...state,
        [payload.listType]: {
          ...state[payload.listType],
          [payload.type]: {
            ...initialState[payload.type],
          },
        },
      };
    default:
      return state;
  }
};

export default filterReducer;
