import React from 'react';
import { Icon } from '../styled';

import searchIcon from './images/search.svg';

const SearchIcon = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={searchIcon} />;
};

export default SearchIcon;
