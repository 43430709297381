import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import moment from 'moment';
import { instanceOf } from 'prop-types';

export default HeaderComponent =>
  class extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
      super(props);
      const { cookies } = props;

      this.state = {
        show: cookies.get('show') || true
      };
    }
    onClosePopup = () => {
      const { cookies } = this.props;
      this.setState({ show: false });
      cookies.set('show', false, {
        path: '/',
        expires: moment(moment().format('YYYY-MM-DD'))
          .add(1, 'days')
          .toDate()
      });
    };

    render() {
      return (
        <HeaderComponent
          onClosePopup={this.onClosePopup}
          {...this.state}
          {...this.props}
        />
      );
    }
  };
