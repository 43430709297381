import React from 'react';
import styled from 'styled-components';

import Rect from './Components/Rect';
import Circle from './Components/Circle';
import Dot from './Components/Dot';

const Wrapper = styled.div.attrs({
  className: 'spinner-wrapper'
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const LoadingIcon = ({ type, ...props }) => {
  if (!type || type === 'circle') {
    return <Circle {...props} />;
  } else if (type === 'rect') {
    return <Rect {...props} />;
  } else if (type === 'dot') {
    return <Dot {...props} />;
  }
};

const Spinner = ({ type, width, height, color, ...props }) => {
  return (
    <Wrapper {...props}>
      <LoadingIcon type={type} width={width} height={height} color={color} />
    </Wrapper>
  );
};

export default Spinner;
