import {
  FETCH_PLAN_DATA,
  CLEAR_PLAN_DATA,
  CHANGE_PLAN_DATA,
  CHANGE_PLAN_ADDITIONAL_DATA,
} from "@/Redux/Actions/plan";

const initialState = {
  data: null,
};

const planReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_PLAN_DATA:
      return {
        ...state,
        data: {
          ...payload.data,
        },
      };
    case CHANGE_PLAN_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          [payload.name]: payload.value,
        },
      };
    case CHANGE_PLAN_ADDITIONAL_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          additional: {
            ...state.data.additional,
            [payload.name]: {
              ...state.data.additional[payload.name],
              value: payload.value,
            },
          },
        },
      };
    case CLEAR_PLAN_DATA:
      return initialState;
    default:
      return state;
  }
};

export default planReducer;
