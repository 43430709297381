import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "signin", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => {
  const {
    user: { isLoggedIn }
  } = state;

  return { isLoggedIn };
};

export default connect(mapStateToProps)(PrivateRoute);
