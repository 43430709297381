import React from "react";
import { hot } from "react-hot-loader/root";

import { connect } from "react-redux";
import objectFitImages from "object-fit-images";

import { fetchUserInfo } from "@/Redux/Actions/user";
import Toast from "@/Components/Toast";

import { isMobile } from "../config";

import { ThemeProvider } from "styled-components";
import theme from "../Themes/default";

import Desktop from "../ViewWeb";
import Mobile from "../ViewMobile";

class App extends React.Component {
  componentDidMount() {
    const { fetchUserInfo } = this.props;
    fetchUserInfo();

    objectFitImages();
    Toast.createContainer({});

    // 리뷰 앱일 경우 경고 알림
    setTimeout(() => {
      try {
        if (
          window.location.hostname.match(/fdding-pr/) &&
          document.getElementById("react-toast")
        ) {
          Toast.error("Review App - 서비스 기능 이용시 주의", "Warning");
        }
      } catch (error) {
        console.log(error);
      }
    }, 0);

    if (window.kakaoPixel) {
      window.kakaoPixel("7799788047279961608").pageView("전체 페이지");
    }
  }
  render() {
    return (
      <ThemeProvider theme={theme}>
        {isMobile ? <Mobile /> : <Desktop />}
      </ThemeProvider>
    );
  }
}

export default connect(null, { fetchUserInfo })(hot(App));
