import styled, { css } from 'styled-components'

import {
  BounceInLeft, 
  BounceInRight,
  BounceInUp,
  BounceInDown, 
} from './animation'

export const Wrap = styled.div`
  width: 578px;
  position: fixed;
  z-index: 10010;

  ${props => {
    switch (props.position) {
      case 'top-left':
        return `
          top: 1em;
          left: 1em;
        `
      case 'top-center':
        return `
          top: 1em;
          left: 50%;
          transform: translate(-50%, 0);
        `
      case 'top-right':
        return `
          top: 1em;
          right: 1em;
        `
      case 'bottom-left':
        return `
          bottom: 1em;
          left: 1em;
        `
      case 'bottom-center':
        return `
          bottom: 1em;
          left: 50%;
          transform: translate(-50%, 0);
        `
      case 'bottom-right':
        return `
          bottom: 1em;
          right: 1em;
        `
      default:
        break;
    }
  }};

  .toast {
    color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    border-top-right-radius: 0;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #f1f1f1;
    animation-duration: 750ms;
    animation-fill-mode: both;

    ${props => {
      switch (props.position) {
        case 'top-left':
          return css`animation-name: ${BounceInLeft};`
        case 'top-center':
          return css`animation-name: ${BounceInDown};`
        case 'top-right':
          return css`animation-name: ${BounceInRight};`
        case 'bottom-left':
          return css`animation-name: ${BounceInLeft};`
        case 'bottom-center':
          return css`animation-name: ${BounceInUp};`
        case 'bottom-right':
          return css`animation-name: ${BounceInRight};`
        default:
          break;
      }
    }};

    & + div {
      margin-top: 10px;
    }
  }
`