import React from 'react';
import { Icon } from '../styled';
import styled from 'styled-components';

import onHeartIcon from './images/ico-heart-on.svg';
import offHeartIcon from './images/ico-heart-off.svg';

const Heart = styled(Icon)`
  background-image: url(${({ checked }) =>
    checked ? onHeartIcon : offHeartIcon});
`;

const HeartIcon = ({ width, height, checked, ...props }) => {
  return <Heart {...props} width={width} height={height} checked={checked} />;
};

export default HeartIcon;
