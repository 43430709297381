import {
  REQUEST_FETCH_DETAIL,
  SUCCESS_FETCH_DETAIL,
  FAIL_FETCH_DETAIL,
  REQUEST_PORTFOLIO,
  SUCCESS_PORTFOLIO,
  FAIL_PORTFOLIO
} from '../Actions/detail';

const initialState = {
  isLoading: true,
  data: null,

  portfolio: {
    isLoading: true,
    list: []
  }
};

const detailReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_FETCH_DETAIL:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_FETCH_DETAIL:
      return {
        ...state,
        data: {
          ...payload.detail
        },
        isLoading: false
      };
    case FAIL_FETCH_DETAIL:
      return {
        ...state,
        isLoading: false
      };

    case REQUEST_PORTFOLIO:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          isLoading: true
        }
      };
    case SUCCESS_PORTFOLIO:
      return {
        ...state,
        portfolio: {
          list: payload.data,
          isLoading: false
        }
      };
    case FAIL_PORTFOLIO:
      return {
        ...state,
        portfolio: {
          ...state.portfolio,
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export default detailReducer;
