import axios from 'axios'
import qs from 'query-string'

class ApiCall {
  constructor() {
    let apiCall = axios.create({
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*'
      }
    })

    apiCall.interceptors.response.use(this.handleSuccess, this.handleError)
    this.apiCall = apiCall
  }

  handleSuccess(response) {
    return response
  }

  handleError = error => {
    let err= {} 
    try {
      err = error.response.data
      err.status = error.response.status
    } catch(e) {
      err = error
    }
    
    console.log('api err', err)
    switch (error.response.status) {
      case 500:
        // this.redirectTo(window, '/500')
        err.msg = '서버에러가 발생하였습니다.'
        break

      case 400:
        // if (err.code === 303) {
        //   this.redirectTo(window, '/force/term')
        // }
        break
      default:
        break
    }
    
    return Promise.reject(err)
  }

  redirectTo = (document, path) => {
    document.location.href = path
  }
  
  get(path, params = {}) {
    return this.apiCall.request({
      method: 'get',
      url: path,
      responseType: 'json',
      params: params
    }).then(response => {
      return Promise.resolve(response.data, response.status)
    })
  }

  post(path, payload = {}) {
    return this.apiCall.request({
      method: 'POST',
      url: path,
      responseType: 'json',
      data: payload
    }).then(response => {
      return Promise.resolve(response.data, response.status)
    })
  }

  put(path, payload = {}) {
    return this.apiCall.request({
      method: 'PUT',
      url: path,
      responseType: 'json',
      data: payload
    }).then(response => {
      return Promise.resolve(response.data, response.status)
    })
  }

  patch(path, payload = {}) {
    return this.apiCall.request({
      method: 'PATCH',
      url: path,
      responseType: 'json',
      data: payload
    }).then(response => {
      return Promise.resolve(response.data, response.status)
    })
  }

  delete(path, params = {}) {
    return this.apiCall.delete(`${path}?${qs.stringify(params)}`).then(response => {
      return Promise.resolve(response.data, response.status)
    })
  }
}

export default new ApiCall()