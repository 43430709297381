export const SELECT_ITEM = "SELECT_ITEM";
export const SELECT_ITEM_INDEX = "SELECT_ITEM_INDEX";
export const SET_SNAP_TYPE = "SET_TYPE";
export const SET_VIEWER_TYPE = "SET_VIEWER_TYPE";
export const CLEAR_VIEWER = "CLEAR_VIEWER";

export const selectItem = (data) => {
  return {
    type: SELECT_ITEM,
    payload: {
      data,
    },
  };
};
export const selectItemIndex = (index) => {
  return {
    type: SELECT_ITEM_INDEX,
    payload: {
      index,
    },
  };
};
export const setSnapType = (snapType) => {
  return {
    type: SET_SNAP_TYPE,
    payload: {
      snapType,
    },
  };
};
export const setViewerType = (viewerType) => {
  return {
    type: SET_VIEWER_TYPE,
    payload: {
      viewerType,
    },
  };
};
export const clearViewer = () => {
  return {
    type: CLEAR_VIEWER,
  };
};
