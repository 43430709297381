import apiCall from '@/Utils/apiCall'

// 로그인
const login = ({ email, password }) => apiCall.post('/api/user/login', {
  id: email,
  password: password,
})

// 비밀번호 찾기
const forgotPassword = ({ email }) => apiCall.get('/api/user/forgotPassword', {id: email})

// 로그아웃
const logout = () => apiCall.post('/api/user/logout')

// 내 정보
const getUser = () => apiCall.get('/api/user')

// 내 정보 변경
const editUser = ({ name, phone }) => apiCall.put('/api/user', {
  name: name,
  phone: phone,
})

export default {
  login,
  logout,
  forgotPassword,
  getUser,
  editUser,
}