import React, { Fragment, useState, useEffect, useRef } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { NavLink, Link, withRouter } from "react-router-dom";

import withController from "./Controller";
import { withCookies } from "react-cookie";
import { logout } from "@/Redux/Actions/user";
import { clearSnapList } from "@/Redux/Actions/snapList";

import Flag from "../_Basic/Flag";

import {
  Header,
  Wrap,
  FlagGroup,
  MainMenu,
  MenuButton,
  MenuDropdownWrapper,
  MenuDropdownButton,
  MenuDropdown,
  TopMenu,
  MenuLink,
  UserMenu,
  UserMenuDrop,
  DropDown,
  MiddleWrap,
  Logo,
  MembershipLink,
  MembershipLogo,
  TopBanner,
  EstimateButton,
  ContractButton,
  HeaderBalloon,
} from "./styled";

const HeaderComponent = ({
  name,
  isLoggedIn,
  logout,
  clearSnapList,
  onClosePopup,
  show,
}) => {
  let path = window.location.pathname;
  return (
    <Header show={show}>
      <TopBanner id="top-estimate-banner">
        <Link to="/estimate">
          <img src={require("./images/ico-calendar.svg")} alt="" />
          <span>원하는 날짜에 촬영 가능한 작가님들을 먼저 찾아보세요!</span>
          <EstimateButton>스케줄 확인하기 <img src={require("./images/ico-estimate-arrow.svg")} alt="" /></EstimateButton>
        </Link>
      </TopBanner>
      {
        (show && <HeaderBalloon>연락처 노출 없이 작가님들의 촬영 스케줄을 한 번에 확인할 수 있어요.</HeaderBalloon>)
      }
      <Wrap>
        <div>
          <ContractButton><span>촬영 표준 계약서 배포</span></ContractButton>

          <TopMenu>
            {isLoggedIn ? (
              <Fragment>
                <li>
                  <MenuLink to="/about">회사 소개</MenuLink>
                </li>
                <UserMenu>
                  <UserMenuDrop>
                    <span>{name}</span> 님
                  </UserMenuDrop>
                  <DropDown>
                    <li>
                      <Link to="/account">내 정보 변경</Link>
                    </li>
                    <li onClick={logout}>
                      <p>로그아웃</p>
                    </li>
                  </DropDown>
                </UserMenu>
                <li>
                  <MenuLink to="/favorite">위시리스트</MenuLink>
                </li>
              </Fragment>
            ) : (
              <Fragment>
                <li>
                  <MenuLink to="/about">회사 소개</MenuLink>
                </li>
                <li>
                  <MenuLink to="/signin">로그인</MenuLink>
                </li>
                <li>
                  <MenuLink to="/signup">회원가입</MenuLink>
                  {/* <SignUp Btn={MenuName} /> */}
                </li>
              </Fragment>
            )}
          </TopMenu>
        </div>
      </Wrap>

      <MiddleWrap>
        <div>
          <Logo to="/" />
          <MembershipLink>
            <MembershipLogo>
              <img src={require('./images/ico-membership.svg')} alt=""/>
              <div>프딩 비즈니스 멤버십 가입 문의</div>
            </MembershipLogo>
          </MembershipLink>
          <MainMenu>
            <MenuButton activeClassName="active" to="/estimate">
              촬영 스케줄 문의
            </MenuButton>
            <MenuButton activeClassName="active" to="/main">
              본식스냅
            </MenuButton>
            <MenuButton activeClassName="active" to="/dvd">
              본식DVD
            </MenuButton>
            <MenuButton activeClassName="active" to="/jeju">
              제주스냅
            </MenuButton>
            <MenuButton activeClassName="active" to="/simple">
              심플스튜디오
            </MenuButton>
            <MenuButton activeClassName="active" to="/dme">
              드레스·메이크업
            </MenuButton>

            {/* 갤러리 운영 종료.. 20. 12. 31
            <MenuButton activeClassName="active" to="/gallery">
              프딩갤러리
            </MenuButton>*/}
            <MenuButton activeClassName="active" to="/event">
              이벤트
            </MenuButton>
            {/* <MenuButton activeClassName="active" to="/magazine">
              매거진
            </MenuButton>
            <MenuButton activeClassName="active" to="/howtouse">
              이용방법

            </MenuButton>
            <MenuButton activeClassName="active" to="/safety-payment">
              <span>촬영 안심결제</span>
              <img src={require("./images/ic-beta.svg")} alt="" />
            </MenuButton>
            */}
          </MainMenu>
        </div>
      </MiddleWrap>
    </Header>
  );
};

export default compose(
  withRouter,
  withCookies,
  connect(
    ({ user: { name, isLoggedIn } }) => ({
      name,
      isLoggedIn,
    }),
    { logout, clearSnapList }
  ),
  withController
)(HeaderComponent);
