export const REQUEST_SNAP_COLLECTION = "REQUEST_SNAP_COLLECTION";
export const SUCCESS_SNAP_COLLECTION = "SUCCESS_SNAP_COLLECTION";
export const FAIL_SNAP_COLLECTION = "FAIL_SNAP_COLLECTION";
export const CLEAR_SNAP_COLLECTION = "CLEAR_SNAP_COLLECTION";

export const requestSnapCollection = type => {
  return {
    type: REQUEST_SNAP_COLLECTION,
    payload: {
      type
    }
  };
};

export const successSnapCollection = data => {
  return {
    type: SUCCESS_SNAP_COLLECTION,
    payload: {
      data
    }
  };
};

export const failSnapCollection = () => {
  return {
    type: FAIL_SNAP_COLLECTION
  };
};

export const clearSnapCollection = () => {
  return {
    type: CLEAR_SNAP_COLLECTION
  };
};
