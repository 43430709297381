import apiCall from '@/Utils/apiCall';

const getPopularList = params => apiCall.get('/api/main/list', params);
const getAdBanner = () => apiCall.get('/api/main/adBanner');
const getListBanner = () => apiCall.get('/api/main/listBanner');

export default {
  getPopularList,
  getAdBanner,
  getListBanner
};
