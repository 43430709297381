import React from 'react';

import styled from 'styled-components';

const FlagLink = styled.a`
  font-size: 12px;
  line-height: 1.5;
  color: #6f6f6f;
`;
const Flag = ({ children, ...props }) => {
  return <FlagLink {...props}>{children}</FlagLink>;
};

export default Flag;
