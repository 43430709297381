import apiCall from "./apiCall";
import getHashTag from "./getHashTag";
import isMobile from "./isMobile";
import Portal from "./portal";
import routeComponent from "./routeComponent";
import functions from "./functions";
import styles from "./styles";
import components from "./components";

import _ from "lodash";

function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
}

const preLoaders = [
  require("@/ViewWeb/images/preloader.gif"),
  require("@/ViewWeb/images/preloader.gif"),
  require("@/ViewWeb/images/preloader.gif"),
  require("@/ViewWeb/images/preloader.gif")
];

const emptyList = [{}, {}, {}, {}];

const img = require("./images/default.png");

/**
 * ex) const obj = {
 *   a: {
 *     foo: 0,
 *     bar: 1
 *   },
 *   b: {
 *     test: 0,
 *     map: 1
 *   }
 * }
 *
 * spreadObj(obj); // { foo: 0, bar: 1, test: 0, map: 1 }
 */
const spreadObj = obj => _.assign({}, ..._.map(obj));

export const getLocalStorage = name => {
  const value = localStorage.getItem(name);

  return value;
};

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, value);
};

export const formatComma = number =>
  number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export {
  apiCall,
  getHashTag,
  isMobile,
  Portal,
  routeComponent,
  guid,
  img,
  preLoaders,
  emptyList,
  spreadObj,
  functions,
  styles,
  components
};
