import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";

import { logout } from "@/Redux/Actions/user";
import WithController from "./Controller";
import Slider from "./Component/Slider";
import { withCookies } from "react-cookie";

import { Header, Logo, LogoWrap, HamBtn, Wrap, TopBanner, HeaderBalloon } from "./styled";

const HeaderComponent = ({
  isOpen,
  onClickToOpen,
  name,
  isLoggedIn,
  location,
  logout,
  onTransition,
  onClosePopup,
  show,
  ...props
}) => {
  const showList = [
    "/",
    "/about",
    "/main",
    "/jeju",
    "/simple",
    "/dme",
    "/dvd",
    "/event",
    "/magazine",
    "/estimate",
    "/safety-payment",
    "/plan",
  ];
  /* "/honeymoon","/gallery","/event","/howtouse" 제거 */
  const hasShow = showList.reduce((r, i) => {
    if (i === "/about" && location.pathname.indexOf(i) !== -1) return true;
    if (i === "/plan" && location.pathname.indexOf(i) !== -1) return true;
    if (i === "/safety-payment" && location.pathname.indexOf(i) !== -1)
      return true;
    if (i === "/event" && location.pathname.indexOf(i) !== -1) return true;
    if (i === "/magazine" && location.pathname.indexOf(i) !== -1) return true;
    if (location.pathname === i) return true;
    if (
      (i === "/main" ||
        i === "/jeju" ||
        i === "/dme" ||
        i === "/simple" ||
        i === "/dvd") &&
      location.pathname.indexOf(i) !== -1
    )
      return true;
    return r;
  }, false);

  if (hasShow) {
    return (
      <Wrap className="main__header">
        <Header show={hasShow === true && location.pathname === "/"}>
          <TopBanner id="top-estimate-banner">
            <Link to="/estimate">
              <img
                src={require("./images/icon-calendar.svg")}
                alt=""
              />
              <span>원하는 날짜에 촬영 가능한 작가님들을 먼저 찾아보세요!</span>
            </Link>
          </TopBanner>
          {(show && <HeaderBalloon>연락처 노출 없이 작가님들의 촬영 스케줄을 <br/>한 번에 확인할 수 있어요.</HeaderBalloon>)}
          <LogoWrap>
            <Link to="/">
              <Logo />
            </Link>
            <HamBtn onClick={onClickToOpen} />
          </LogoWrap>
        </Header>
        {isOpen && (
          <Slider handler={onClickToOpen} onTransition={onTransition} />
        )}
      </Wrap>
    );
  }

  return null;
};

export default compose(
  withRouter,
  withCookies,
  connect(({ user: { name, isLoggedIn } }) => ({ name, isLoggedIn }), {
    logout,
  }),
  WithController
)(HeaderComponent);
