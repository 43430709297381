export const CHANGE_LIST_KEYWORD = 'CHANGE_LIST_KEYWORD';
export const CLEAR_LIST_KEYWORD = 'CLEAR_LIST_KEYWORD';

export const CHANGE_WEDDINGHALL_KEYWORD = 'CHANGE_WEDDINGHALL_KEYWORD';
export const CLEAR_WEDDINGHALL_KEYWORD = 'CLEAR_WEDDINGHALL_KEYWORD';

export const changeListKeyword = input => {
  return {
    type: CHANGE_LIST_KEYWORD,
    payload: {
      input
    }
  };
};

export const clearListKeyword = () => {
  return {
    type: CLEAR_LIST_KEYWORD
  };
};

export const changeWeddingHallKeyword = input => {
  return {
    type: CHANGE_WEDDINGHALL_KEYWORD,
    payload: {
      input
    }
  };
};

export const clearWeddingHallKeyword = () => {
  return {
    type: CLEAR_WEDDINGHALL_KEYWORD
  };
};
