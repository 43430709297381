import React from 'react';
import {
  Footer,
  Wrap,
  Logo,
  Info,
  Right,
  Terms,
  Content,
  Condition,
  Privacy,
  OuterLink,
  Membership,
  SNSWrap,
  Insta,
  Blog,
  Facebook
} from './styled';

const FooterComponent = () => {
  return (
    <Footer>
      <Wrap>
        <Logo />

        <Info>
          <div>
            <p>
              (주) 프딩 &nbsp;| &nbsp;대표이사 임지훈 &nbsp;| &nbsp;서울특별시 도봉구 마들로13길 84, 창동2층 217호 (창동,서울창업허브)
            </p>
            <p>
              사업자등록번호 &nbsp;406-81-04732 &nbsp;| &nbsp;통신판매업신고
              &nbsp;제2024-서울도봉-0081호
            </p>
            <p>
              대표전화 &nbsp;070-7722-1930 &nbsp; | &nbsp; 제휴 및 가입 문의 &nbsp;cs@fdding.com
            </p>
            <p>개인정보책임자 임지훈</p>
            <p>프딩은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 상품, 거래 정보 및 거래에 대하여 책임지지 않습니다.</p>
          </div>
          <p>© 2018 FDDING INC. ALL RIGHTS RESERVED</p>
        </Info>

        <Right>
          <Terms>
            <Membership>비즈니스멤버십</Membership>
            <OuterLink href="https://blog.naver.com/fddingofficial/222157664820">
              촬영 표준계약서
            </OuterLink>
            <Condition>이용약관</Condition>
            <Privacy>개인정보취급방침</Privacy>
          </Terms>

          <Content>
            <p>
              고객센터 &nbsp;<span>070-7722-1930</span>
            </p>
            <p>
              <span>MON - FRI AM 11 ~ PM 6</span>, SAT - SUN 휴무
            </p>
            <p>
              카카오톡 플러스친구 &nbsp;<span>프딩</span>
            </p>
          </Content>

          <SNSWrap>
            <Insta />
            <Blog />
            <Facebook />
          </SNSWrap>
        </Right>
      </Wrap>
    </Footer>
  );
};

export default FooterComponent;
