import checkMobile from "./Utils/isMobile";

export const isMobile = checkMobile.any();
export const platform = isMobile ? "mobile" : "desktop";
export const isSafari = /^((?!chrome|android).)*safari/i.test(
  navigator.userAgent
);

export const MIN_WIDTH = 1440;

// public 폴더 패스
export const PUBLIC_PATH =
  process.env.NODE_ENV === "development" ? "" : "/static";
export const PUBLIC_IMAGE_PATH = `${PUBLIC_PATH}/images`;

// static 이미지 경로
const DEV_STATIC_IMG_URL = `/images/${platform}`;
export const PROD_STATIC_IMG_URL = `https://static.fdding.com/fdding/${platform}`;
export const STATIC_IMG_URL =
  process.env.NODE_ENV === "development"
    ? DEV_STATIC_IMG_URL
    : PROD_STATIC_IMG_URL;

// 이미지 경로
const DEV_IMG_URL = "https://s3.ap-northeast-2.amazonaws.com/fdding-dev-image";
const PROD_IMG_URL = "https://image.fdding.com";
export const IMG_URL =
  process.env.NODE_ENV === "development" ? DEV_IMG_URL : PROD_IMG_URL;

const DEV_ETC_IMG_URL =
  "https://s3.ap-northeast-2.amazonaws.com/fdding-dev-etc-image";
const PROD_ETC_IMG_URL =
  "https://s3.ap-northeast-2.amazonaws.com/fdding-prod-etc-image";
export const ETC_IMG_URL =
  process.env.NODE_ENV === "development" ? DEV_ETC_IMG_URL : PROD_ETC_IMG_URL;

// 이미지 path
export const IMG_PATH = [
  "logo",
  "business",
  "etc",
  "album",
  "jeju",
  "main",
  "dress",
  "dme",
];

// 상품 타입
export const TYPES = ["main", "jeju", "dme", "dress"];

// 작가유형
export const PHOTOGRAPHER = [
  { key: 0, name: "대표" },
  { key: 1, name: "수석실장" },
  { key: 2, name: "실장" },
];

export const EventConfig = {
  MAIN001: {
    label: `비교 견적 이벤트 신청`,
    snapType: `본식 스냅`,
    target: `예식일이 견적 요청일로 부터 2개월 이내인 고객 대상`,
    text: `예식일이 2개월도 남지 않은 고객이시라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 스케줄 가능 여부와 특별 할인 견적을 요청해보세요.`,
    placeholder: {
      message:
        "예) 안녕하세요. 본식 일정이 얼마 남지 않은 커플입니다. 저희 예식일에 촬영 가능하신 작가님께서는 본식 스냅 + 원판 촬영 상품으로 이벤트 견적 부탁드립니다! ",
    },
  },
  MAIN002: {
    label: `비교 견적 이벤트 신청`,
    snapType: `본식 스냅`,
    target: `촬영 희망일이 견적 요청일로부터 6개월 이상 남은 고객 대상`,
    text: `본식 스냅 촬영 희망일이 6개월 이상 여유 있으신 고객이시라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 견적 요청을 해보세요.\n스케줄이 가능한 본식 스냅 작가님들에게 특별 할인가 제안을 받아보실 수 있어요.  `,
    placeholder: {
      message:
        "예) 안녕하세요. 훌륭하신 본식 스냅 작가님들과 할인된 가격으로 촬영하고 싶어서 내년 6월 촬영 미리 예약하려고 해요. 많은 작가님들의 관심 부탁 드립니다~ ",
    },
  },
  MAIN003: {
    label: `비교 견적 이벤트 신청`,
    snapType: `본식 스냅`,
    target: `4성~5성급 호텔 예식 촬영 고객 전용 할인 이벤트`,
    text: `4성~5성급 호텔에서 예식을 준비 중이시라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 견적 요청을 해보세요.\n스케줄이 가능한 본식 스냅 작가님들에게 특별 할인가 제안을 받아보실 수 있어요. `,
    placeholder: {
      message:
        "예) 안녕하세요. 호텔 예식이다보니 가급적 2인작가 촬영 상품으로 이벤트 견적 제안 주시면 좋을 것 같아요. 양가 부모님 앨범 포함 가격으로 안내 부탁드립니다!",
    },
  },
  MAIN004: {
    label: `비교 견적 이벤트 신청`,
    snapType: `본식 스냅`,
    target: `일요일 예식 고객 대상 할인 이벤트`,
    text: `예식일이 일요일인 고객이시라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 견적 요청을 해보세요.\n스케줄이 가능한 본식 스냅 작가님들에게 특별 할인가 제안을 받아보실 수 있어요.`,
    placeholder: {
      message:
        "예) 안녕하세요. 일요일 오후 2시 예식입니다. 오전 메이크업 촬영부터 폐백, 원판까지 모두 촬영하고 싶어요. 훌륭하신 작가님들의 많은 이벤트 견적 부탁드립니다~",
    },
  },
  JEJU001: {
    label: `비교 견적 이벤트 신청`,
    snapType: `제주 웨딩스냅`,
    target: `촬영 희망일이 견적 요청일로 부터 2개월 이내인 고객 대상`,
    text: `2개월 이내에 긴급하게 제주 웨딩 스냅 촬영이 진행되어야 하시나요?\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 스케줄 가능 여부와 특별 할인 견적을 요청해보세요.  `,
    placeholder: {
      message:
        "예) 안녕하세요. 급하게 제주 웨딩스냅 촬영을 진행하게 된 예신입니다. 저의 촬영 예정일에 촬영 가능하신 작가님이 계시다면 이벤트 견적 부탁드립니다. 저희는 최대한 빠르게 예약 진행하고 싶어요~",
    },
  },
  JEJU002: {
    label: `비교 견적 이벤트 신청`,
    snapType: `제주 웨딩스냅`,
    target: `촬영 희망일이 견적 요청일로부터 6개월 이상 남은 고객 대상`,
    text: `제주 웨딩스냅 촬영 희망일이 6개월 이상 여유 있으신 고객이시라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 견적 요청을 해보세요.\n스케줄이 가능한 제주 웨딩스냅 작가님들에게 특별 할인가 제안을 받아보실 수 있어요.`,
    placeholder: {
      message:
        "예) 안녕하세요. 급하게 제주 웨딩스냅 촬영을 진행하게 된 예신입니다. 저의 촬영 예정일에 촬영 가능하신 작가님이 계시다면 이벤트 견적 부탁드립니다. 저희는 최대한 빠르게 예약 진행하고 싶어요~",
    },
  },
  JEJU003: {
    label: `비교 견적 이벤트 신청`,
    snapType: `제주 웨딩스냅`,
    target: `12월~ 3월 제주 웨딩스냅 촬영 고객 대상  `,
    text: `겨울 시즌 제주 웨딩 스냅 촬영을 준비 중이라면\n엄격한 프딩 입점 심사를 통과하신 작가님들에게 견적 요청을 해보세요.\n스케줄이 가능한 제주 웨딩스냅 작가님들에게 특별 할인가 제안을 받아보실 수 있어요.`,
    placeholder: {
      message:
        "예) 안녕하세요. 제주 겨울 동백꽃 촬영 사진이 너무 마음에 들어서 겨울 촬영 결정하게 된 예신입니다. 드레스와 메이크업 포함된 상품으로 이벤트 견적 부탁드립니다!",
    },
  },
  JEJU4: {
    label: `이벤트 신청`,
    snapType: `제주 웨딩스냅`,
    target: `촬영 업체 샘플 모델 촬영 계약 동의 고객 대상`,
    text: `최고의 포트폴리오를 준비 중이신 제주 웨딩스냅 작가님의 모델 커플이 되어보세요.\n고객님의 웨딩 촬영을 무료로 진행하실 수 있습니다.\n단, 작가님이 의도하시는 촬영 컨셉의 연출이 가능한 스타일의 커플인지\n확인할 수 있도록 고객님의 SNS 공개 계정 정보를 함께 제출해주세요. `,
    placeholder: {
      message:
        "예) 안녕하세요. 저희 예랑은 30대 초반에 모델 경력이 있고, 저는 20대 후반의 일반인입니다. 전문 모델로는 부족하지만 제 인스타그램 계정 이미지 보시고 저희 커플과 컨셉이 맞으시는 작가님들의 많은 관심 부탁드립니다.",
    },
  },
  LTJJ01: {
    label: `제휴 이벤트 신청`,
    snapType: `공통`,
    target: `경품 : 제주스냅 촬영권, 롯데호텔 제주 숙박권&디너 뷔페 식사권 등`,
    text: `소중한 사람들과 제주에서 해보고 싶었던 여행 버킷리스트가 있나요?\n당신만의 따뜻한 스토리를 들려주세요.\n\n11월 중 따뜻한 사연을 접수해주신 분들 중, 추첨을 통해\n프리미엄 제주 스냅 촬영의 기회와 롯데호텔 제주 숙박권을 드립니다.`,
    placeholder: {
      message:
        "예) 테스트",
    }
  },
  REVIEW01: {
    label: `프딩 이용 후기\n블로그 포스팅 이벤트`,
    snapType: `공통`,
    target:`블로그 포스팅 Event`,
    text: `촬영 중개 플랫폼 '프딩'에서\n제주스냅, 본식스냅, DVD 촬영 업체를 검색하고\n예약 문의, 스케줄 확인하기, 예약 결제, 촬영을 완료해 보신 분들\n모두 참여 가능한 후기 이벤트입니다.`,
    placeholder: {
      message: "",
    }
  }
};

// 비교견적 config
export const EstimateConfig = {
  jeju: {
    placeholder: {
      message: `예) 안녕하세요. 저희는 제주 웨딩 스냅에 반해서 많이 알아보고 있어요. 인기 작가님들은 6개월 전부터 예약이 마감된다고해서 빨리 예약하려고 합니다. 드레스와 메이크업이 포함된 상품으로 견적 부탁드립니다!`,
    },
  },
  main: {
    placeholder: {
      message: `예) 안녕하세요. 저희 예식일에 가능하신 실력있는 본식 스냅 작가님들의 견적을 받아보고 싶습니다. 본식 + 원판 상품으로 견적 부탁드립니다. 그리고 저희는 호텔 예식이라서 2인 작가 촬영 상품 가격도 함께 견적 부탁드려요.`,
    },
  },
  simple: {
    placeholder: {
      message: `예) 안녕하세요. 최대한 간소한 리허설 촬영을 찾다가 심플 스튜디오를 알게 되었어요. 많은 견적 부탁드립니다!`,
    },
  },
  dme: {
    placeholder: {
      message: `예) 안녕하세요. 제주 웨딩스냅 촬영에 필요한 드레스 메이크업 견적을 받아보고 싶습니다. 촬영 헬퍼 비용이 포함된 견적 부탁드립니다!`,
    },
  },
  dvd: {
    placeholder: {
      message: `예) 안녕하세요. 저희 예식일에 가능하신 실력있는 본식 DVD 영상 작가님들의 견적을 받아보고 싶습니다. 1인 2캠 구성에 관심이 많지만 더 좋은 상품 구성이 있으시다면 추천 부탁드립니다.`,
    },
  },
};

// 색상값
export const COLOR = {
  gray_6: "#f8f8f8",
  gray_5: "#ededed",
  gray_4: "#ccc",
  gray_3: "#bdbdbd",
  gray_2: "#a3a3a3",
  gray_1: "#6f6f6f",
  gray_0: "#333",
  blue_0: "#5099fd",
  red_1: "#b53b49",
  red_0: "#dd4c5c",
  green_0: "#53cb8c",
  white_0: "#fff",
  black_0: "#000",
};
