export const CHANGE_FILTER = "CHANGE_FILTER";
export const RESET_FILTER = "RESET_FILTER";

export const changeFilter = ({ listType, type, filters }) => {
  return {
    type: CHANGE_FILTER,
    payload: {
      listType,
      type,
      filters
    }
  };
};

export const resetFilter = type => {
  return {
    type: RESET_FILTER,
    payload: {
      type
    }
  };
};
