import React from "react";
import styled, { css } from "styled-components";

import ClearIcon from "./Clear";
import SearchIcon from "./Search";
import CloseIcon from "./Close";
import HeartIcon from "./Heart";
import HomeIcon from "./Home";
import ShareIcon from "./Share";
import Arrow from "./Arrow";
import ClipboardIcon from "./Clipboard";
import BackIcon from "./Back";
import SmsIcon from "./Sms";
import GridIcon from "./Grid";
import CardIcon from "./Card";
import CamIcon from "./Cam";
import KakaoChIcon from "./KakaoCh";
import InstagramIcon from "./Instagram";
import KakaoTalkIcon from "./KakaoTalk";
import InquiryIcon from "./Inquire";

export {
  HeartIcon,
  ClearIcon,
  SearchIcon,
  CloseIcon,
  HomeIcon,
  ShareIcon,
  Arrow,
  ClipboardIcon,
  BackIcon,
  SmsIcon,
  GridIcon,
  CardIcon,
  CamIcon,
  KakaoChIcon,
  InstagramIcon,
  KakaoTalkIcon,
  InquiryIcon
};

const SvgWrapper = styled.span`
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: ${(props) => props.width && `${props.width}px`};
  height: ${(props) => props.height && `${props.height}px`};

  ${(props) =>
    props.size &&
    css`
      width: ${props.size}px;
      height: ${props.size}px;
    `}
`;
const SvgImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`;

const Icon = ({ icon, ...props }) => {
  if (!icon) {
    return null;
  }

  const svgPath = require(`./icons/icon-${icon}.svg`);

  return (
    <SvgWrapper {...props}>
      <SvgImg src={svgPath} />
    </SvgWrapper>
  );
};

export default Icon;
