import { call, put, takeLatest } from "redux-saga/effects";

import {
  REQUEST_POPULAR_LIST,
  successPopularList,
  failPopularList,
  REQUEST_ADBANNER,
  successAdBanner,
  failAdBanner,
  REQUEST_LIST_BANNER,
  successListBanner,
  failListBanner
} from "../Actions/home";

import { home } from "./api";

function* fetchPopularList({ payload }) {
  const { type } = payload;

  try {
    const data = yield call(home.getPopularList, {
      type
    });

    yield put(successPopularList(data));
  } catch (e) {
    yield put(failPopularList());
  }
}

export function* watchFetchPopularList() {
  yield takeLatest([REQUEST_POPULAR_LIST], fetchPopularList);
}

function* fetchAdBanner() {
  try {
    const data = yield call(home.getAdBanner);

    yield put(successAdBanner(data));
  } catch (e) {
    yield put(failAdBanner());
  }
}

export function* watchFetchAdBanner() {
  yield takeLatest([REQUEST_ADBANNER], fetchAdBanner);
}

function* fetchListBanner() {
  try {
    const data = yield call(home.getListBanner);

    yield put(successListBanner(data));
  } catch (e) {
    yield put(failListBanner());
  }
}

export function* watchFetchListBanner() {
  yield takeLatest([REQUEST_LIST_BANNER], fetchListBanner);
}
