import React from 'react';
import whiteBackIcon from './images/ico-back-white.svg';
import blackBackIcon from './images/ico-back-black.svg';

import { Icon } from '../styled';

const WhiteBackIcon = ({ ...props }) => {
  return <Icon {...props} src={whiteBackIcon} />;
};

const BlackBackIcon = ({ ...props }) => {
  return <Icon {...props} src={blackBackIcon} />;
};

const BackIcon = ({ width, height, color, ...props }) => {
  if (color === 'black') {
    return <BlackBackIcon {...props} width={width} height={height} />;
  } else {
    return <WhiteBackIcon {...props} width={width} height={height} />;
  }
};

export default BackIcon;
