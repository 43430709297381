import {
  UPDATE_SAFETY_PAYMENT_DATA,
  CLEAR_SAFETY_PAYMENT_DATA
} from "../Actions/safetyPayment";

const initialState = {
  data: null
};

const safetyPaymentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SAFETY_PAYMENT_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload.data
        }
      };
    case CLEAR_SAFETY_PAYMENT_DATA:
      return initialState;
    default:
      return state;
  }
};

export default safetyPaymentReducer;
