import React from 'react';
import { Icon } from '../styled';

import shareIcon from './images/ico-share.svg';

const ShareIcon = ({ width, height, ...props }) => {
  return <Icon {...props} width={width} height={height} src={shareIcon} />;
};

export default ShareIcon;
