import {
  SET_CONFIG,
  REQUEST_SNAP_WEDDINGHALL,
  SUCCESS_SNAP_WEDDINGHALL,
  FAIL_SNAP_WEDDINGHALL,
  CLEAR_SNAP_WEDDINGHALL
} from '../Actions/snapWeddingHall';

const initialState = {
  type: null,
  folderId: null,
  keyword: null,

  isLoading: false,

  hasMore: false,
  items: [],
  nextPage: 1,
  page: 1,
  limit: 30
};

const snapWeddingHallReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONFIG:
      return {
        ...state,
        type: payload.type,
        folderId: payload.folderId,
        keyword: payload.keyword
      };
    case REQUEST_SNAP_WEDDINGHALL:
      return {
        ...state,
        // currentType: payload.type,
        isLoading: true
      };
    case SUCCESS_SNAP_WEDDINGHALL:
      return {
        ...state,
        ...payload.data,
        items: [...state.items, ...payload.data.items],
        isLoading: false
      };
    case FAIL_SNAP_WEDDINGHALL:
      return {
        ...state,
        isLoading: false
      };
    case CLEAR_SNAP_WEDDINGHALL:
      return initialState;

    default:
      return state;
  }
};

export default snapWeddingHallReducer;
