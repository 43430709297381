import React, { Component } from "react";

const RouteContainer = RouteComponent =>
  class extends Component {
    componentDidMount = () => {
      this.trackPage(this.props);
    };
    trackPage = () => {
      window.scrollTo(0, 0);
    };

    render() {
      return <RouteComponent {...this.props} />;
    }
  };
export default RouteContainer;
