import {
  REQUEST_FAVORITE_LIST,
  SUCCESS_FAVORITE_LIST,
  FAIL_FAVORITE_LIST
} from "../Actions/favorite";

const initialState = {
  isLoading: true,

  main: [],
  dvd: [],
  jeju: [],
  simple: [],
  dme: []
};

const favoriteReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REQUEST_FAVORITE_LIST:
      return {
        ...state,
        isLoading: true
      };
    case SUCCESS_FAVORITE_LIST:
      return {
        ...state,
        ...payload.data,
        isLoading: false
      };
    case FAIL_FAVORITE_LIST:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
};

export default favoriteReducer;
