import React, { Component } from "react";
import { cloneDeep } from "lodash";
import moment from "moment";
import { Cookies } from "react-cookie";
import { instanceOf } from "prop-types";

const Controller = Wrapper =>
  class extends Component {
    static propTypes = {
      cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
      super(props);
      const { cookies } = props;

      this.state = {
        show: cookies.get("show") || true,
        isOpen: false,
        y: 0
      };
    }

    onClosePopup = () => {
      const { cookies } = this.props;
      this.setState({ show: false });
      cookies.set("show", false, {
        path: "/",
        expires: moment(moment().format("YYYY-MM-DD"))
          .add(1, "days")
          .toDate()
      });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.location.pathname !== this.props.location.pathname) {
        this.setState({
          isOpen: false
        });
      }
    }

    onClickToOpen = () => {
      const y = cloneDeep(window.scrollY);
      this.setState(
        state => {
          return {
            isOpen: !state.isOpen,
            y: state.isOpen ? state.y : y
          };
        },
        () => {
          if (this.state.isOpen) {
            window.scrollTo(0, 0);
          } else {
            window.scrollTo(0, this.state.y);
          }
        }
      );
    };

    onTransition = url => () => {
      const { history } = this.props;
      this.setState(
        {
          isOpen: false,
          y: 0
        },
        () => {
          history.push(url);
        }
      );
    };

    render() {
      return (
        <Wrapper
          {...this.state}
          {...this.props}
          onClosePopup={this.onClosePopup}
          onClickToOpen={this.onClickToOpen}
          onTransition={this.onTransition}
        />
      );
    }
  };

export default Controller;
