import styled from "styled-components";
import Balloon from "@/ViewMobile/Components/Balloon";

export const Wrap = styled.div`
  position: sticky;
  width: 100%;
  height: 100%;

  top: 0;
  right: 0;
  z-index: 20;
`;

export const Header = styled.header`
  width: 100%;
  height: auto;

  border-bottom: 1px solid #ededed;
  background: #fff;
`;

export const LogoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 45px;
`;

export const HamBtn = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  width: 24px;
  height: 24px;
  background: url(${require("./images/m-ico-menu.svg")}) center center no-repeat;
  background-size: cover;
  transform: translateY(-50%);
`;

export const Logo = styled.div`
  width: 60px;
  height: 25px;
  background: url(${require("./images/m-fd-logo.svg")}) center center no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TopBanner = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 7px;
    text-align: center;
    font-size: 12px;
    line-height: normal;
    color: #fff;
    background-color: #7F343C;

    > span {
      display: inline-block;
      margin-left: 8px;
      font-weight: normal;
    }
  }
`;

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  border: 0;
  background: transparent url(${require("./images/m-ico-close.svg")}) center
    center no-repeat;
  background-size: cover;
  position: absolute;
  top: 8px;
  right: 16px;
`;

export const HeaderBalloon = styled(Balloon)`
  width: 78%;
  position: absolute;
  text-align: left;
  top: 38px;
  left: 50%;
  background-color: #242424;
  border-radius: 2px;
  color: #fff;
  font-size: 11.5px;
  margin: 0 auto;
  border: 0;
  z-index: 9999;
  transform: translateX(-50%);
  & + button {
    margin-top: 10px;
    margin-right: 11px;
  }

  &:after {
    content: '';
    position: absolute;
    border-bottom: 8px solid #242424;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    top: -7px;
    left: 65%;
  }
`;
