import apiCall from "@/Utils/apiCall";

export const getDetail = ({ type, seq }) =>
  apiCall.get(`/api/product/${type}/${seq}`);

export const getPortfolio = ({ type, enterpriseId }) =>
  type === "dvd"
    ? apiCall.get(`/api/portfolio/folder/dvd`, { type, enterpriseId })
    : apiCall.get(`/api/portfolio/folder`, { type, enterpriseId });

export default {
  getDetail,
  getPortfolio
};
